.js-main-page-container{
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  left: 0;
}
body.menu-open{
  overflow: hidden;

  .js-main-page-container{
    left: 270px;
  }

  header.main-header{
    left: 270px;
  }
}
.menu-mobil-overlay{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba($color-primary, 0.7);
  z-index: 99999999;
  top: 0;
  display: none;
  cursor: url(/assets/online/img/cursor-arrow-left.png) 35 35, auto;
}

.menu-mobile-button{
  font-size: 1.8em;
  padding-left: 15px;
  height: 100%;
  z-index: 99999999;
  color: $color-gray-secondary;
  border-right: solid 1px $color-gray-secondary;

  i{
    font-size: 1.6em;
    color: $color-primary;
  }

  &:hover{
    color: $color-primary;
  }

  &.search-mobile{
    left: initial;
    right: 0;
    display: none !important;
  }

}

.menu-mobil-box{
  display: inline-block;
  position: fixed;
  z-index: 99999999;
  background-color: $color-white;
  width: 85%;
  max-width: 270px;
  min-width: 270px;
  min-height: 100%;
  top: 0;
  left: -270px;
  transition: all 0.45s;
  height: 100%;
  overflow-y: scroll;

  // Background Gradient
  // background-image: -webkit-linear-gradient(171deg, $color-text-primary 0%, $color-secondary 100%);
  // background-image: -o-linear-gradient(171deg, $color-text-primary 0%, $color-secondary 100%);
  // background-image: linear-gradient(261deg, $color-text-primary 0%, $color-secondary 100%);

  header{
    text-align: center;
    padding: 30px;
    display: inline-block;
    width: 100%;
    float: left;
    padding-bottom: 10px;
    img{
      max-width: 100%;
      width: 70px;
    }

    img.logo{
      max-height: 55px;
    }
    h2{
      color: $color-text-primary;
      small{
          color: $color-primary;
      }
    }
  }

  ul.menu{
    padding: 0;
    list-style: none;
    margin: 0;
    float: left;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;

    h1{
      color: $color-text-primary;
      padding: 20px 40px;
      margin: 0;
      font-size: 2.4em;
      border-top: solid 2px $color-gray-primary;
    }

    li{
      display: block;

      @for $i from 1 to 10 {
        &:nth-child(#{$i}) a {
          -webkit-transition: all 0.45s;
          -o-transition: all 0.45s;
          transition: all 0.45s;
          -webkit-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          transform: translateX(-100%);
        }
      }

      a{
        display: block;
        padding: 14px 30px;
        width: 100%;
        color: $color-text-secondary;
        text-decoration: none;
        font-family: $font-family-secondary;
        font-size: 1.6em;
        font-weight: $fw-semi-bold;
        position: relative;

        &::before{
          content: '|';
          position: absolute;;
          left: 0;
          top: 50%;
          background-color: $color-primary;
          color: $color-primary;
          overflow: hidden;
          height: 0px;
          transition: all 0.25s;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        &:hover{
          color: $color-primary;
          background-color: $color-gray-tertiary;

          &::before{
            height: 100%;
          }
        }

        &.cta{
          margin-bottom: 15px;

          span{
            background-color: $color-primary;
            color: $color-white;
            width: 100%;
            display: inline-block;
            padding: 10px;
            font-size: 14px;
            text-align: center;
            position: relative;
            font-weight: bold;

            i{
              position: absolute;
              left: 20px;
              color: $color-white;
              opacity: 1;
              top: 0;
              line-height: 40px;
            }
          }

        }

        &.logout{
          background-color: rgba($color-gray-secondary, 0.2);
          text-align: left;
          color: $color-primary;
        }

        i{
          width: 30px;
          opacity: 0.5;
          color: $color-primary;
        }
      }

      &.submenu-box{
        background-color: rgba($color-black, 0.1);

        .submenu{
          padding-left: 0;

          li{
            a{
              padding-left: 60px;
            }
          }
        }
      }

      &.active{
        a{
          color: $color-primary;
          background-color: $color-gray-secondary;

          &:before{
            height: 100%;
          }
        }
      }
    }

    &.menu-secondary{
      border-top: solid 1px rgba($color-white, 0.1);
      opacity: 0;
      -webkit-transition: all 1.5s;
      -o-transition: all 1.5s;
      transition: all 1.5s;

      li{
        a{
          font-size: 1.4em;
          padding-top: 10px;
          padding-bottom: 10px;
          opacity: 0.6;
          &:before{
            background-color: rgba($color-white, 0.2);
            width: 2px;
            color: transparent;
          }

        &:hover{

          &:before{
            height: 100%;
            width: 2px;
          }
        }

        }
      }
    }
  }

  &.open{
    left: 0;
    transform: none;

    ul.menu{
      li{

        @for $i from 1 to 10 {
          &:nth-child(#{$i}) a {
            -webkit-transition: all $i * 0.15s;
            -o-transition: all $i * 0.15s;
            transition: all $i * 0.15s;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
          }
        }

      }
    }

    ul.menu.menu-secondary{
      opacity: 1;
    }
  }
}
