////////////////////////////////////////////////////////////////////////////////


// General Styles
*{
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

// html{
//   overflow-x: hidden;
// }
body{
  font-family: $font-family;
  color: $color-text-primary;
  font-size: 10px;
  background-color: $color-primary;
  padding-top: 69px;
}


.container{
  width: 100%;
  max-width: 1000px;
}

////////////////////////////////////////////////////////////////////////////////


// Sections


.title-section{
  // margin-bottom: 30px;

  .title{
    font-family: $font-family-secondary;
    margin-bottom: 15px;
  }
  p{
    color: $color-text-secondary;
  }
}
.subtitle-section{
  margin-bottom: $padding/2;
}


////////////////////////////////////////////////////////////////////////////////


// Links & Buttons

a{
  text-decoration: none;
  color: inherit;
}
a:hover{
  cursor: pointer;
  color: $color-text-primary;
}
a:focus{
  outline: 0;
}


////////////////////////////////////////////////////////////////////////////////

// Autocomplete
.ui-widget.ui-widget-content{
  border: 0;
  margin-top: -2px;
}
.ui-menu{
  .ui-menu-item{
    width: 100% !important;
    font-size: 1.4em;
    padding: 8px 10px;
    font-family: Museo Sans;
    color: $color-text-primary;

    &:hover{
      background-color: $color-primary;
      color: $color-white;
      strong{
        color: $color-white;
        &.ui-state-active{
          color: $color-white;
        }
      }
    }

    strong{
      font-weight: bold !important;
      padding: 0;
      color: $color-primary;

      &.ui-state-active{
        border-color: transparent;
        background: none;
        color: $color-primary;
      }
    }
  }
}


// Animations
.a-bounce{
  -webkit-transition-timing-function: cubic-bezier(0.680, 0, 0.370, 1); /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.680, -0.220, 0.370, 1.325);
  -moz-transition-timing-function: cubic-bezier(0.680, -0.220, 0.370, 1.325);
  -o-transition-timing-function: cubic-bezier(0.680, -0.220, 0.370, 1.325);
  transition-timing-function: cubic-bezier(0.680, -0.220, 0.370, 1.325); /* custom */
}
