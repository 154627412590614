* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Lato";
  color: #4C433B;
  font-size: 10px;
  background-color: #224878;
  padding-top: 69px;
}

.container {
  width: 100%;
  max-width: 1000px;
}

.title-section .title {
  font-family: "Klavika";
  margin-bottom: 15px;
}

.title-section p {
  color: #8C8C8C;
}

.subtitle-section {
  margin-bottom: 40px;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  cursor: pointer;
  color: #4C433B;
}

a:focus {
  outline: 0;
}

.ui-widget.ui-widget-content {
  border: 0;
  margin-top: -2px;
}

.ui-menu .ui-menu-item {
  width: 100% !important;
  font-size: 1.4em;
  padding: 8px 10px;
  font-family: Museo Sans;
  color: #4C433B;
}

.ui-menu .ui-menu-item:hover {
  background-color: #224878;
  color: #fff;
}

.ui-menu .ui-menu-item:hover strong {
  color: #fff;
}

.ui-menu .ui-menu-item:hover strong.ui-state-active {
  color: #fff;
}

.ui-menu .ui-menu-item strong {
  font-weight: bold !important;
  padding: 0;
  color: #224878;
}

.ui-menu .ui-menu-item strong.ui-state-active {
  border-color: transparent;
  background: none;
  color: #224878;
}

.a-bounce {
  -webkit-transition-timing-function: cubic-bezier(0.68, 0, 0.37, 1);
  /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.22, 0.37, 1.325);
  -moz-transition-timing-function: cubic-bezier(0.68, -0.22, 0.37, 1.325);
  -o-transition-timing-function: cubic-bezier(0.68, -0.22, 0.37, 1.325);
  transition-timing-function: cubic-bezier(0.68, -0.22, 0.37, 1.325);
  /* custom */
}

.va-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.left {
  float: left;
}

.right {
  float: right;
}

.no-float {
  float: none;
}

.null-padding {
  padding: 0 !important;
}

.null-padding-left {
  padding-left: 0 !important;
}

.null-padding-right {
  padding-right: 0 !important;
}

.null-padding-top {
  padding-top: 0 !important;
}

.null-padding-bottom {
  padding-bottom: 0 !important;
}

.null-padding-side {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bg-s-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.bg-a-fixed {
  background-attachment: fixed;
}

.bg-primary {
  background-color: #224878;
}

.bg-secondary {
  background-color: #2B2B2B;
}

.bg-text-primary {
  background-color: #4C433B;
}

.bg-gray-primary {
  background-color: #A5A9AD;
}

.bg-gray-secondary {
  background-color: #EDEDED;
}

.bg-gray-tertiary {
  background-color: #f7f7f7;
}

.bg-white {
  background-color: #fff;
}

.bg-black {
  background-color: #000;
}

.color-text-primary {
  color: #224878;
}

.color-text-secondary {
  color: #2B2B2B;
}

.color-text-gray-primary {
  color: #A5A9AD;
}

.color-text-gray-secondary {
  color: #EDEDED;
}

.color-text-white {
  color: #fff;
}

.color-text-black {
  color: #000;
}

.color-text-1 {
  color: #4C433B;
}

.color-text-2 {
  color: #8C8C8C;
}

.font-regular {
  font-weight: 400;
}

.font-semibold {
  font-weight: 500;
}

.font-bold {
  font-weight: 600;
}

.girar-90 {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
}

.girar-180 {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}

.girar-270 {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
}

.mbm-multiply {
  mix-blend-mode: multiply;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section-small {
  padding-top: 40px;
  padding-bottom: 40px;
}

.section-short {
  padding-top: 20px;
  padding-bottom: 20px;
}

.masonry-item {
  display: inline-block;
  float: none;
  margin-left: -1px;
  margin-right: -2px;
  vertical-align: top;
}

.masonry-va-top {
  vertical-align: top;
}

.masonry-va-middle {
  vertical-align: middle !important;
}

.photo {
  background-position: center;
}

.vertical-line {
  width: 1px;
  min-height: 100px;
  background-color: #A5A9AD;
  display: inline-block;
}

.colum-grid {
  padding-left: 5px;
  padding-right: 5px;
}

@font-face {
  font-family: 'Klavika';
  src: url(/assets/online/base/scss/fonts/klavika-regular.eot), url(/assets/online/base/scss/fonts/klavika-regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Klavika';
  src: url(/assets/online/base/scss/fonts/klavika-medium.eot), url(/assets/online/base/scss/fonts/klavika-medium.ttf);
  font-weight: 500;
}

.wysihtml5-toolbar .dropdown {
  display: none;
}

h1.banner-title {
  font-size: 5.6em;
  font-weight: 400;
  margin: 0 0 30px 0px;
}

h1.hero-heading {
  font-size: 3.6em;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  color: #4C433B;
  margin: 0;
}

h1 {
  font-size: 3.2em;
  font-family: "Klavika";
  font-weight: 400;
}

h2 {
  font-size: 2.6em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.8em;
}

h5 {
  font-size: 1.6em;
}

h6 {
  font-size: 1.4em;
}

p {
  font-size: 1.4em;
  line-height: 1.6em;
}

p.paragraph-smaller {
  font-size: 1.4em;
  line-height: 1.6em;
}

hr {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.row-buttons-group {
  margin-bottom: 80px;
}

.button {
  text-decoration: none;
  font-size: 2em;
  font-family: "Klavika";
  font-weight: 500;
  line-height: 22px;
  background-color: #224878;
  padding: 20px;
  color: #fff;
  display: inline-block;
  border: 0;
  transition: all 0.25s;
  text-align: center;
  position: relative;
  margin-bottom: 5px;
  min-width: 230px;
}

.button i {
  position: absolute;
  background-color: transparent;
  left: 0;
  top: 0;
  width: 50px;
  line-height: 62px;
}

.button:hover {
  color: #fff;
  text-decoration: none;
  box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.15);
}

.button:focus {
  outline: 0;
  color: #fff;
  text-decoration: none;
}

.button-responsive {
  width: 100%;
  max-width: 280px;
}

.button-large {
  font-size: 2.4em;
  padding: 20px 40px;
}

.button-medium {
  font-size: 1.6em;
  font-weight: 500;
  padding: 15px 30px;
}

.button-medium i {
  line-height: 52px;
}

.button-small {
  font-size: 1.4em;
  padding: 5px 20px;
}

.button-small i {
  line-height: 32px;
}

.button-short {
  font-size: 1.4em;
  padding: 5px 15px;
}

.button-primary {
  background-color: #224878;
}

.button-secondary {
  background-color: #2B2B2B;
}

.button-tertiary {
  background-color: #4C433B;
  color: #fff;
}

.button-white {
  background-color: #fff;
  color: #224878;
}

.button-white:hover {
  color: #224878;
}

.button-white:focus {
  color: #224878;
}

.button-outline {
  border: solid 1px #224878;
  background-color: transparent;
  color: #4C433B;
  font-weight: bold;
}

.button-outline:hover, .button-outline:focus {
  background-color: #224878;
  color: #fff;
}

.button-outline.active {
  border-color: #224878;
  background-color: #224878;
  color: #fff;
}

.button-edit {
  background-color: #4a86e8;
  font-weight: 500;
}

.button-icon {
  padding-left: 40px;
  font-size: 1.6em;
  font-weight: 400;
  background-color: transparent;
  color: #4C433B;
  box-shadow: 0px 0px 0px 1px #224878 inset;
}

.button-icon i {
  position: absolute;
  background-color: transparent;
  left: 0;
  top: 0;
  width: 60px;
  line-height: 42px;
  border-radius: 50%;
}

.button-icon:hover {
  background-color: #224878;
  color: #fff;
}

.button-icon.button-small i {
  line-height: 32px;
}

.button-circle {
  width: 50px;
  line-height: 50px;
  border: solid 1px #A5A9AD;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 1.6em;
  margin: 0 10px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.button-circle:hover {
  border-color: #224878;
  color: #fff;
  background-color: #224878;
}

.button-input {
  border-radius: 0;
}

.form-title {
  color: #4C433B;
  margin: 0;
  font-family: "Klavika";
}

.form-title span {
  color: #2B2B2B;
}

.form-title-label {
  color: #8C8C8C;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.4em;
  width: 100%;
  display: inline-block;
}

.form-field-box {
  position: relative;
  margin-bottom: 0px;
  margin-top: 0;
  float: left;
  width: 100%;
}

.form-field-box .form-field {
  width: 100%;
  border: 1px solid #d4d4d4;
  font-size: 1.4em;
  padding: 15px 20px;
  line-height: 20px;
  margin: 0;
  margin-top: -1px;
  margin-left: -1px;
  color: #4C433B;
  position: relative;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 50px;
  text-overflow: ellipsis;
  font-weight: 500;
}

.form-field-box .form-field.error {
  border-color: #e74c3c;
  z-index: 2;
}

.form-field-box .form-field:focus {
  outline: 0;
  border-color: #224878;
  z-index: 99;
}

.form-field-box .field-label-placeholder {
  position: relative;
  font-size: 1.4em;
  margin: 0;
  color: #2B2B2B;
  transition: all 0.20s;
  font-weight: 400;
}

.form-field-box label.error {
  margin: 0;
  margin-top: 5px;
  float: left;
  text-align: right;
  color: #224878;
  border-radius: 2px;
}

.form-field-box label.error i {
  position: absolute;
  font-size: 2.4em;
  right: 20px;
  top: -14px;
  color: #224878;
}

.form-field-box label.error span {
  font-size: 1.4em;
}

.form-field-box i.validation {
  position: absolute;
  right: 20px;
  font-size: 2em;
}

.form-field-box i.validation.validation-error {
  color: #224878;
}

.form-field-box i.validation.validation-success {
  color: #4C433B;
}

.form-field-box.field-error .form-field {
  border-color: #224878;
}

.form-field-box.field-error .field-label-placeholder {
  color: #8C8C8C;
}

.form-field-box.form-field-select::before {
  content: "\f107";
  color: #4C433B;
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.8em;
}

.form-field-box.form-field-select.field-error .select2-selection.select2-selection--single .select2-selection__rendered {
  border-color: #224878;
}

.form-field-box.form-field-icon .field-icon {
  background-color: #EDEDED;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 42px;
  height: 42px;
  text-align: center;
  display: inline-block;
  line-height: 42px;
  font-size: 1.8em;
  border-radius: 2px 0px 0px 2px;
  border: 0px;
}

.form-field-box.form-field-icon .form-field {
  padding-left: 60px;
}

.form-field-box.form-field-icon.field-focus .field-icon {
  background-color: #224878;
}

.form-field-box.field-small {
  margin-bottom: 15px;
}

.form-field-box.field-small .form-field {
  padding: 8px 10px;
}

.form-field-box.field-small .button {
  padding: 8px 25px 7px 25px;
}

.form-field-box.field-small.form-field-icon .form-field {
  padding-left: 60px;
}

.form-field-box.field-small.form-field-icon .field-icon {
  height: 38px;
  width: 50px;
}

.form-field-box.field-small.form-field-select .select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
  padding: 8px 10px;
}

.form-field-box-md {
  margin-bottom: 35px;
}

.form-field-box-md .form-field {
  border-color: transparent;
  border: 0px;
  border-bottom: 1px solid #d4d4d4;
  border-radius: 0;
  padding: 10px 0px;
  margin: 0;
}

.form-field-box-md .form-field:focus {
  border-bottom-color: #2B2B2B;
}

.form-field-box-md i.validation {
  right: 0;
}

.form-field-box-md i.validation.validation-success {
  color: #EDEDED;
}

.form-field-box-md .field-label-placeholder {
  left: 0px;
  top: -2px;
  font-size: 1.2em;
  color: #8C8C8C;
}

.form-field-box-md.field-focus .form-field {
  border-color: transparent;
  border-bottom-color: #2B2B2B;
}

.form-field-box-md.field-focus .form-field:focus {
  border-color: transparent;
  border-bottom-color: #2B2B2B;
}

.form-field-box-md.field-focus .field-label-placeholder {
  color: #8C8C8C;
}

.form-field-box-md.field-error .form-field {
  border-color: #fff;
  border-bottom-color: #224878;
}

.form-field-box-md.form-field-icon .field-icon {
  background-color: transparent;
  text-align: left;
  color: #224878;
  border: 0;
}

.form-field-box-icon .form-field-icon {
  position: absolute;
  left: 0;
  height: 100%;
  display: inline-block;
}

.form-field-box.radio {
  margin: 0;
  margin-bottom: 30px;
  float: left;
  width: 100%;
}

.form-field-box.radio label.label-radio {
  font-size: 1.6em;
  padding-left: 30px;
}

.form-field-box.radio label.label-radio input.form-field-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: solid 1px #EDEDED;
  border-radius: 50%;
  margin-top: 0;
  margin-left: -30px;
  position: absolute;
  transition: all 0.25s;
}

.form-field-box.radio label.label-radio input.form-field-radio:focus {
  outline: 0;
  border: solid 2px #224878;
}

.form-field-box.radio label.label-radio input.form-field-radio:hover {
  border: solid 2px #224878;
}

.form-field-box.radio label.label-radio input.form-field-radio:checked {
  border: solid 10px #224878;
}

.button-checkbox {
  float: left;
  margin-right: 5px;
}

.button-checkbox .button {
  margin-right: 0;
}

.button-checkbox label {
  transition: all 0.25s;
  cursor: pointer;
}

.button-checkbox label:hover {
  border-color: #224878;
}

.button-checkbox input.option {
  display: none;
}

.button-checkbox input.option:checked + label {
  background-color: #224878;
  border-color: #224878;
  color: #fff;
}

.ui-widget.ui-widget-content {
  z-index: 99999999999 !important;
}

.js-action-button span {
  opacity: 1;
  transition: all 0.5s;
}

.js-action-button .loader-img {
  position: absolute;
  width: 30px;
  left: 50%;
  margin-left: -15px;
  top: 50%;
  margin-top: -15px;
  mix-blend-mode: lighten;
  transition: all 1s;
  opacity: 0;
  transform: scale(0);
}

.js-action-button.loading span {
  opacity: 0;
}

.js-action-button.loading .loader-img {
  opacity: 1;
  transform: scale(1);
}

textarea {
  min-height: 50px;
  font-size: 1.4em;
  line-height: 1.6em;
  resize: vertical;
}

.form-field-checkbox {
  margin-top: 10px;
}

.form-field-checkbox label {
  cursor: pointer;
}

.form-field-checkbox .form-label {
  font-size: 1.4em;
  display: inline-block;
  margin-right: 10px;
}

.form-field-checkbox .form-check-icon {
  width: 20px;
  line-height: 20px;
  background-color: #A5A9AD;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
}

.form-field-checkbox input {
  display: none;
}

.form-field-checkbox input:checked + .form-check-icon {
  background-color: #77d118;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 250px;
}

.form-field-box.form-field-select .select2-container {
  width: 100% !important;
}

.form-field-box.form-field-select .select2-container .select2-selection.select2-selection--single {
  border: solid 0px #EDEDED;
  height: auto;
  width: 100%;
  background-color: transparent;
}

.form-field-box.form-field-select .select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 10px;
  font-size: 1.4em;
  line-height: 15px !important;
  line-height: normal;
}

.form-field-box.form-field-select .select2-container .select2-selection__arrow {
  height: 100%;
  width: 40px;
  display: none;
}

.form-field-box.form-field-select .select2-container.select2-container--focus .select2-selection.select2-selection--single .select2-selection__rendered {
  border-color: #224878;
}

.form-field-box.form-field-select .select2-container.select2-container--open .select2-selection.select2-selection--single .select2-selection__rendered {
  border-color: #224878;
}

.select2-container--default .select2-selection--multiple {
  padding: 5px;
  padding-bottom: 0;
  border: solid 1px #d4d4d4;
  background-color: transparent;
  border-radius: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
  overflow: visible;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  padding: 5px 10px;
  border: 0;
  background-color: #224878;
  color: #fff;
  font-size: 1.4em;
  line-height: 1em;
  font-weight: 600;
  position: relative;
  margin: 0;
  margin-right: 2px;
  margin-bottom: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  color: #fff;
  position: absolute;
  right: -10px;
  margin: 0;
  top: -5px;
  background-color: #2B2B2B;
  width: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 10px;
  opacity: 0;
  z-index: 9;
  transition: all 0.25s;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice:hover .select2-selection__choice__remove {
  opacity: 1;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #2B2B2B;
}

.select2-container--default.select2-container--open .select2-selection--multiple {
  border-color: #2B2B2B;
}

.select2-container--default .select2-search--inline {
  font-size: 1.4em;
  height: 27px;
}

.select2-container--default .select2-search--inline .select2-search__field {
  padding: 11px;
  font-size: 1em !important;
  margin: 0;
}

.form-field-box.form-field-box-md.form-field-select .select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
  border: 0px;
  border-bottom: solid 1px #EDEDED;
  padding: 10px;
  line-height: 1.4em;
  padding-left: 30px;
  text-align: left;
  border-radius: 0;
}

.form-field-box.form-field-box-md.form-field-select .select2-container .select2-selection__arrow {
  display: none;
}

.select2-container {
  z-index: 1000;
}

.select2-container:focus {
  outline: 0;
}

.select2-container .select2-selection:focus {
  outline: 0;
}

.select2-container .select2-dropdown {
  border-radius: 0;
  border: solid 0px #224878;
  border-top: 0px;
  margin-top: -2px;
  z-index: 99999;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
}

.select2-container .select2-dropdown .select2-results .select2-results__options li {
  font-size: 1.6em;
  padding: 15px;
}

.select2-container .select2-dropdown .select2-results .select2-results__options li.select2-results__option--highlighted {
  background-color: #2B2B2B;
}

.select2-container .select2-dropdown .select2-results .select2-results__options li[aria-selected=true] {
  background-color: #2B2B2B;
  color: #8C8C8C;
  display: none;
}

.datepicker.dropdown-menu {
  padding: 0;
  background-clip: border-box;
  border: none;
  border-radius: 0;
  animation: popup .2s ease-out forwards;
}

.datepicker.dropdown-menu table {
  background-color: #173050;
}

.datepicker.dropdown-menu table thead {
  background-color: #173050;
}

.datepicker.dropdown-menu table thead tr {
  border-top: 0;
}

.datepicker.dropdown-menu table tbody {
  background-color: white;
}

.datepicker.dropdown-menu table tr {
  border-top: 1px solid #A5A9AD;
}

.datepicker.dropdown-menu table tr td, .datepicker.dropdown-menu table tr th {
  width: auto;
  height: 24px;
  padding: 8px;
  font-size: 16px;
  border-radius: 0;
  transition: background-color .6s ease-out;
}

@media screen and (min-width: 400px) {
  .datepicker.dropdown-menu table tr td, .datepicker.dropdown-menu table tr th {
    padding: 12px;
  }
}

.datepicker.dropdown-menu table tr td:hover, .datepicker.dropdown-menu table tr td.active:hover, .datepicker.dropdown-menu table tr td:active:hover, .datepicker.dropdown-menu table tr th:hover, .datepicker.dropdown-menu table tr th.active:hover, .datepicker.dropdown-menu table tr th:active:hover {
  border-color: #A5A9AD;
  border-radius: 0;
  transition: background-color .2s ease-out;
}

.datepicker.dropdown-menu table tr td.focused, .datepicker.dropdown-menu table tr td.selected, .datepicker.dropdown-menu table tr th.focused, .datepicker.dropdown-menu table tr th.selected {
  background-color: #224878;
  border-color: #A5A9AD;
  border-radius: 0;
  color: #fff;
}

.datepicker.dropdown-menu table tr td.active, .datepicker.dropdown-menu table tr td.active:focus, .datepicker.dropdown-menu table tr td.active.active:hover, .datepicker.dropdown-menu table tr td:active:focus, .datepicker.dropdown-menu table tr td.highlighted, .datepicker.dropdown-menu table tr th.active, .datepicker.dropdown-menu table tr th.active:focus, .datepicker.dropdown-menu table tr th.active.active:hover, .datepicker.dropdown-menu table tr th:active:focus, .datepicker.dropdown-menu table tr th.highlighted {
  background-color: #224878;
  border-color: #A5A9AD;
  color: #fff;
}

.datepicker.dropdown-menu table tr th.datepicker-switch {
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  text-transform: uppercase;
  letter-spacing: .05em;
  color: #fff;
  background-color: inherit;
  border-radius: 0;
  text-align: center;
  background-color: #224878;
}

.datepicker.dropdown-menu table tr th.prev,
.datepicker.dropdown-menu table tr th.next {
  color: #fff;
  background-color: #224878;
  border-radius: 0;
}

.datepicker.dropdown-menu table tr th.dow {
  padding: 2px 12px;
  font-size: 12px;
  line-height: 2;
  color: #224878;
  background-color: #fff;
  border-bottom: 1px solid #A5A9AD;
  border-radius: 0;
  text-align: center;
}

.datepicker.dropdown-menu table tr td.day {
  width: 24px;
  border-right: 1px solid #ededed;
  text-align: center;
}

.datepicker.dropdown-menu table tr td.day:last-child {
  border-right-width: 0;
}

.datepicker.dropdown-menu table tr td.range {
  background-color: #224878;
}

.datepicker.dropdown-menu .new,
.datepicker.dropdown-menu .old,
.datepicker.dropdown-menu .disabled,
.datepicker.dropdown-menu .disabled:hover {
  color: #EDEDED;
}

.card-product {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  float: left;
}

.card-product .cp-slider-box {
  position: relative;
  margin-bottom: 10px;
  border-radius: 2px;
  overflow: hidden;
}

.card-product .cp-slider-box .cp-slider {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

.card-product .cp-slider-box .cp-slider .cp-image {
  width: 100%;
  height: 180px;
  background-position: center;
}

.card-product .cp-slider-box .cp-slider .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.25s;
  background-color: rgba(34, 72, 120, 0);
  z-index: 999;
}

.card-product .cp-slider-box .cp-slider:hover .overlay {
  background-color: rgba(34, 72, 120, 0.3);
}

.card-product .cp-slider-box .card-thumbs {
  position: absolute;
  z-index: 99999;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  bottom: 0;
  display: inline-block;
}

.card-product .cp-slider-box .card-thumbs li.item {
  display: inline-block;
  background-color: red;
  height: 8px;
  width: 100%;
  float: left;
  background-color: #2B2B2B;
  border: solid 1px rgba(255, 255, 255, 0.25);
  transition: all 0.25s;
}

.card-product .cp-slider-box .card-thumbs li.item.cycle-pager-active {
  background-color: #224878;
  border-color: #224878;
}

.card-product h4, .card-product h4 {
  margin: 0;
}

.card-product .cp-product-size {
  position: absolute;
  bottom: 15px;
  left: 10px;
  color: #fff;
  font-size: 1.4em;
  z-index: 999;
}

.card-product .cp-favorite {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 2em;
  opacity: 0.5;
  transition: all 0.25s;
  z-index: 999;
}

.card-product .cp-favorite:hover {
  color: #224878;
  opacity: 1;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

.card-product .cp-favorite.active {
  color: #224878;
  opacity: 1;
}

.card-product .cp-location h4 {
  color: #4C433B;
  font-weight: 500;
  font-size: 1.4em;
}

.card-product .cp-location h4 span {
  color: #224878;
}

.card-product .cp-price-box {
  text-align: right;
}

.card-product .cp-price-box .cp-price-dollar {
  font-weight: 500;
  color: #224878;
  margin-bottom: 5px;
}

.card-product .cp-price-box .cp-price-soles {
  color: #EDEDED;
  font-weight: 500;
}

.card-product:hover .cp-slider-box a.overlay {
  background-color: rgba(34, 72, 120, 0.25);
}

.card-product .slider-tags {
  top: 0;
  left: 0;
  right: auto;
}

.card-product .slider-tags .item {
  width: 30px;
  line-height: 30px;
}

.card-product .slider-tags .item img {
  max-width: 18px;
  max-height: 20px;
}

.referencias {
  display: inline-block;
  float: none;
  width: 49%;
  vertical-align: top;
}

.card-referencia {
  background-color: #fff;
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.card-referencia .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
}

.card-referencia:hover {
  box-shadow: 0px 5px 19px -6px rgba(0, 0, 0, 0.15);
  transition: 0.25s;
}

.card-referencia:hover .icon-delete {
  opacity: 1;
  transform: scale(1);
}

.card-referencia .ref-tipo {
  background-color: #224878;
  padding: 15px;
  color: #fff;
  text-align: center;
  font-size: 1.4em;
  font-weight: 700;
  position: relative;
  z-index: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-referencia .ref-tipo i {
  position: absolute;
  right: -8px;
  font-size: 2.1em;
  color: #224878;
  top: 50%;
  margin-top: -10px;
}

.card-referencia .ref-inmueble {
  background-color: #2B2B2B;
  padding: 15px;
  color: #fff;
  text-align: center;
  font-size: 1.4em;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-referencia .card-body {
  padding: 20px;
}

.card-referencia .icon-delete {
  right: -15px;
  top: -15px;
  position: absolute;
  font-size: 1.7em;
  z-index: 2;
  opacity: 0;
  transform: scale(0);
  transition: all 0.25s;
}

.card-referencia .icon-delete i {
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  background-color: #224878;
  color: #fff;
}

.card-referencia .icon-delete:hover {
  cursor: pointer;
}

.card-referencia .icon-inmueble {
  font-size: 17px;
  color: #224878;
  margin-right: 10px;
}

.card-referencia .nombre_ref {
  font-weight: 700;
  font-size: 1.5em;
  color: #2B2B2B;
}

.card-referencia .casa_ref {
  display: inline-block;
  font-size: 1.6em;
  font-weight: 500;
  color: #224878;
}

.card-referencia .text_ref {
  font-size: 1.4em;
  font-weight: 500;
  color: #224878;
  margin-bottom: 10px;
}

.card-referencia .cant_ref {
  font-size: 1.6em;
  font-weight: 700;
  color: #2B2B2B;
  margin-bottom: 10px;
}

.card-referencia .cant_ref_soles {
  font-size: 1.4em;
  font-weight: 700;
  color: #EDEDED;
  margin-bottom: 0px;
}

.cp-favorite {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 2em;
  opacity: 0.5;
  transition: all 0.25s;
  z-index: 999;
}

.cp-favorite:hover {
  color: #224878;
  opacity: 1;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

.cp-favorite.active {
  color: #224878;
  opacity: 1;
}

.jnq-icons {
  font-family: 'jnq_icons';
  font-style: normal;
  display: inline-block;
  line-height: 1;
}

.styleguide-icons {
  text-align: center;
  margin-bottom: 80px;
}

.styleguide-icons .jnq-icons {
  font-size: 6em;
  color: #224878;
  margin: 0 40px;
}

header.main-header {
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  position: fixed;
  width: 100%;
  z-index: 9999999;
  top: 0px;
  height: 70px;
  left: 0;
  background-color: #fff;
  border-bottom: solid 1px #EDEDED;
}

header.main-header .container {
  max-width: 1280px;
}

header.main-header .logo {
  display: inline-block;
  padding-top: 15px;
}

header.main-header .logo * {
  display: inline-block;
}

header.main-header .logo img {
  max-width: 90px;
}

header.main-header.fixed-header {
  position: fixed;
  top: 0;
  background-color: #fff;
}

header.main-header .header-search-input-box {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 94.69px;
  display: none;
}

header.main-header .header-search-input-box .field-icon {
  left: 94px;
  line-height: 70px;
  height: 100%;
  background-color: transparent;
  width: 43px;
  color: #224878;
  font-size: 1.4em;
}

header.main-header .header-search-input-box .form-field {
  height: 70px;
  padding: 0;
  padding-left: 52px;
  font-weight: 600;
  color: #4C433B;
  border: 0;
  line-height: 70px;
  background-color: #fff;
}

header.main-header .header-search-input-box.field-focus .field-icon {
  background-color: transparent;
}

header.main-header .header-search-input-box input:focus + .button {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

header.main-header .header-search-input-box .button {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  height: 100%;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

header.main-header .submenu-user-actions-box.submenu-user-logged .submenu-user-actions li a {
  padding: 10px 20px;
}

.header-search-overlay {
  z-index: 999999;
  opacity: 0.75;
  display: none;
  cursor: url(/assets/online/img/cursor-arrow-up.png) 35 35, auto;
}

body.header-search-open {
  overflow: hidden;
}

body.admin-front .top-header-box {
  top: 37px;
}

body.admin-front header.main-header {
  top: 73px;
}

body.admin-front header.main-header.fixed-header {
  top: 37px;
}

.home-page-class header.main-header ul.menu.menu-horizontal li a:hover {
  color: #224878;
}

.online-view header.main-header {
  position: absolute;
  top: 36px;
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}

.online-view header.main-header.fixed-header {
  position: fixed;
  top: 0;
}

ul.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}

ul.menu li a {
  text-decoration: none;
  font-size: 1.4em;
}

ul.menu.menu-horizontal li {
  display: inline-block;
  float: left;
}

ul.menu.menu-horizontal li a {
  display: inline-block;
  padding: 24px 15px;
  transition: all 0.25s;
  color: #8C8C8C;
  font-family: "Klavika";
  font-weight: 500;
  font-size: 1.6em;
}

ul.menu.menu-horizontal li a:hover {
  color: #224878;
  background-color: #EDEDED;
  box-shadow: inset 0px -4px 0px #224878;
}

ul.menu.menu-horizontal li a.menu-cta {
  background-color: #224878;
  color: #fff;
  padding: 24px 35px;
  border-radius: 0;
}

ul.menu.menu-horizontal li a.menu-cta i {
  margin-right: 10px;
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
}

ul.menu.menu-horizontal li a.menu-cta:hover {
  color: #fff !important;
  background-color: #2B2B2B;
  box-shadow: 0px 0px 0px;
}

ul.menu.menu-horizontal li a.menu-search-btn {
  cursor: text;
}

ul.menu.menu-horizontal li a.menu-search-btn i {
  color: #4C433B;
  margin-right: 15px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

ul.menu.menu-horizontal li a.menu-search-btn:hover {
  margin-right: 20px;
}

ul.menu.menu-horizontal li a.menu-search-btn:hover i {
  margin-right: 20px;
}

ul.menu.menu-horizontal li.active a {
  color: #224878;
  background-color: #EDEDED;
  box-shadow: inset 0px -4px 0px #224878;
}

ul.menu.menu-horizontal li.active a.menu-cta {
  background-color: #2B2B2B;
  color: #fff;
  box-shadow: none;
}

ul.menu.menu-horizontal .submenu-box {
  position: relative;
}

ul.menu.menu-horizontal .submenu-box li a {
  border-width: 700;
}

ul.menu.menu-horizontal .submenu-box .submenu {
  position: absolute;
  background-color: #fff;
  padding: 0;
  right: -10px;
  top: 80px;
  border-radius: 2px;
  width: 180px;
  display: none;
}

ul.menu.menu-horizontal .submenu-box .submenu li {
  display: block;
  width: 100%;
  position: relative;
}

ul.menu.menu-horizontal .submenu-box .submenu li a {
  display: block;
  width: 100%;
  color: #8C8C8C;
  padding: 10px 15px;
  text-align: right;
}

ul.menu.menu-horizontal .submenu-box .submenu li a i {
  width: 20px;
  text-align: right;
  opacity: 0.25;
  transition: all 0.25s;
}

ul.menu.menu-horizontal .submenu-box .submenu li a .icon {
  margin-left: 5px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

ul.menu.menu-horizontal .submenu-box .submenu li a:hover .icon {
  margin-left: 10px;
}

ul.menu.menu-horizontal .submenu-box .submenu li a:hover i {
  width: 30px;
  color: #224878;
  opacity: 1;
}

ul.menu.menu-horizontal .submenu-box .submenu .tri {
  position: absolute;
  top: -15px;
  right: 25px;
}

ul.menu.menu-horizontal .submenu-box .submenu .tri i {
  color: #fff;
  font-size: 2.4em;
}

ul.menu.menu-horizontal .lang-btn a {
  border-left: solid 1px rgba(237, 237, 237, 0.5);
  color: #224878;
}

ul.menu.menu-horizontal .counter {
  background-color: #224878;
  color: #fff;
  font-size: 10px;
  padding: 0px 4px;
  line-height: 20px;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  font-style: normal;
  position: absolute;
  top: 15px;
  right: 5px;
}

ul.menu.menu-horizontal .notifications-btn .button-user-action {
  background-color: transparent;
  border-radius: 0;
  border-left: solid 1px rgba(237, 237, 237, 0.5);
  cursor: pointer;
}

ul.menu.menu-horizontal .notifications-btn .button-user-action .icon {
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
}

ul.menu.menu-horizontal .messages-btn a {
  border-left: solid 1px rgba(237, 237, 237, 0.5);
  padding-left: 20px;
  padding-right: 20px;
}

ul.menu.menu-horizontal .messages-btn a .icon {
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
}

ul.menu.menu-horizontal .messages-btn a:hover {
  background-color: rgba(237, 237, 237, 0.5);
}

ul.menu.menu-horizontal .perfil-menu-box a {
  position: relative;
  padding-right: 60px;
}

ul.menu.menu-horizontal .perfil-menu-box .perfil {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 17px;
  background-color: #A5A9AD;
  background-image: url("/assets/online/img/profile.jpg");
}

ul.menu.menu-horizontal.menu-1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}

ul.menu.menu-horizontal.menu-2 {
  position: absolute;
  right: 0;
  top: 0;
}

ul.menu.menu-busqueda-type {
  margin-top: 7px;
}

ul.menu.menu-busqueda-type li a {
  padding: 6px 10px;
}

ul.menu.menu-busqueda-type li a:hover {
  color: #224878;
}

ul.menu.menu-busqueda-type li.active a {
  background-color: #224878;
  color: #fff;
  border-radius: 2px;
}

ul.menu.menu-busqueda-type li.active a:hover {
  opacity: 0.9;
  color: #fff;
}

ul.menu.menu-vertical {
  width: 100%;
  margin-bottom: 15px;
  margin: 0;
}

ul.menu.menu-vertical li a {
  padding: 15px 0px;
  display: inline-block;
  color: #8C8C8C;
  width: 100%;
  border-bottom: solid 1px #EDEDED;
}

ul.menu.menu-vertical li a i {
  color: #224878;
  margin-right: 10px;
}

.js-main-page-container {
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  left: 0;
}

body.menu-open {
  overflow: hidden;
}

body.menu-open .js-main-page-container {
  left: 270px;
}

body.menu-open header.main-header {
  left: 270px;
}

.menu-mobil-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 72, 120, 0.7);
  z-index: 99999999;
  top: 0;
  display: none;
  cursor: url(/assets/online/img/cursor-arrow-left.png) 35 35, auto;
}

.menu-mobile-button {
  font-size: 1.8em;
  padding-left: 15px;
  height: 100%;
  z-index: 99999999;
  color: #EDEDED;
  border-right: solid 1px #EDEDED;
}

.menu-mobile-button i {
  font-size: 1.6em;
  color: #224878;
}

.menu-mobile-button:hover {
  color: #224878;
}

.menu-mobile-button.search-mobile {
  left: initial;
  right: 0;
  display: none !important;
}

.menu-mobil-box {
  display: inline-block;
  position: fixed;
  z-index: 99999999;
  background-color: #fff;
  width: 85%;
  max-width: 270px;
  min-width: 270px;
  min-height: 100%;
  top: 0;
  left: -270px;
  transition: all 0.45s;
  height: 100%;
  overflow-y: scroll;
}

.menu-mobil-box header {
  text-align: center;
  padding: 30px;
  display: inline-block;
  width: 100%;
  float: left;
  padding-bottom: 10px;
}

.menu-mobil-box header img {
  max-width: 100%;
  width: 70px;
}

.menu-mobil-box header img.logo {
  max-height: 55px;
}

.menu-mobil-box header h2 {
  color: #4C433B;
}

.menu-mobil-box header h2 small {
  color: #224878;
}

.menu-mobil-box ul.menu {
  padding: 0;
  list-style: none;
  margin: 0;
  float: left;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
}

.menu-mobil-box ul.menu h1 {
  color: #4C433B;
  padding: 20px 40px;
  margin: 0;
  font-size: 2.4em;
  border-top: solid 2px #A5A9AD;
}

.menu-mobil-box ul.menu li {
  display: block;
}

.menu-mobil-box ul.menu li:nth-child(1) a {
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.menu-mobil-box ul.menu li:nth-child(2) a {
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.menu-mobil-box ul.menu li:nth-child(3) a {
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.menu-mobil-box ul.menu li:nth-child(4) a {
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.menu-mobil-box ul.menu li:nth-child(5) a {
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.menu-mobil-box ul.menu li:nth-child(6) a {
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.menu-mobil-box ul.menu li:nth-child(7) a {
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.menu-mobil-box ul.menu li:nth-child(8) a {
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.menu-mobil-box ul.menu li:nth-child(9) a {
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.menu-mobil-box ul.menu li a {
  display: block;
  padding: 14px 30px;
  width: 100%;
  color: #8C8C8C;
  text-decoration: none;
  font-family: "Klavika";
  font-size: 1.6em;
  font-weight: 500;
  position: relative;
}

.menu-mobil-box ul.menu li a::before {
  content: '|';
  position: absolute;
  left: 0;
  top: 50%;
  background-color: #224878;
  color: #224878;
  overflow: hidden;
  height: 0px;
  transition: all 0.25s;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menu-mobil-box ul.menu li a:hover {
  color: #224878;
  background-color: #f7f7f7;
}

.menu-mobil-box ul.menu li a:hover::before {
  height: 100%;
}

.menu-mobil-box ul.menu li a.cta {
  margin-bottom: 15px;
}

.menu-mobil-box ul.menu li a.cta span {
  background-color: #224878;
  color: #fff;
  width: 100%;
  display: inline-block;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  position: relative;
  font-weight: bold;
}

.menu-mobil-box ul.menu li a.cta span i {
  position: absolute;
  left: 20px;
  color: #fff;
  opacity: 1;
  top: 0;
  line-height: 40px;
}

.menu-mobil-box ul.menu li a.logout {
  background-color: rgba(237, 237, 237, 0.2);
  text-align: left;
  color: #224878;
}

.menu-mobil-box ul.menu li a i {
  width: 30px;
  opacity: 0.5;
  color: #224878;
}

.menu-mobil-box ul.menu li.submenu-box {
  background-color: rgba(0, 0, 0, 0.1);
}

.menu-mobil-box ul.menu li.submenu-box .submenu {
  padding-left: 0;
}

.menu-mobil-box ul.menu li.submenu-box .submenu li a {
  padding-left: 60px;
}

.menu-mobil-box ul.menu li.active a {
  color: #224878;
  background-color: #EDEDED;
}

.menu-mobil-box ul.menu li.active a:before {
  height: 100%;
}

.menu-mobil-box ul.menu.menu-secondary {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  opacity: 0;
  -webkit-transition: all 1.5s;
  -o-transition: all 1.5s;
  transition: all 1.5s;
}

.menu-mobil-box ul.menu.menu-secondary li a {
  font-size: 1.4em;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: 0.6;
}

.menu-mobil-box ul.menu.menu-secondary li a:before {
  background-color: rgba(255, 255, 255, 0.2);
  width: 2px;
  color: transparent;
}

.menu-mobil-box ul.menu.menu-secondary li a:hover:before {
  height: 100%;
  width: 2px;
}

.menu-mobil-box.open {
  left: 0;
  transform: none;
}

.menu-mobil-box.open ul.menu li:nth-child(1) a {
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.menu-mobil-box.open ul.menu li:nth-child(2) a {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.menu-mobil-box.open ul.menu li:nth-child(3) a {
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.menu-mobil-box.open ul.menu li:nth-child(4) a {
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.menu-mobil-box.open ul.menu li:nth-child(5) a {
  -webkit-transition: all 0.75s;
  -o-transition: all 0.75s;
  transition: all 0.75s;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.menu-mobil-box.open ul.menu li:nth-child(6) a {
  -webkit-transition: all 0.9s;
  -o-transition: all 0.9s;
  transition: all 0.9s;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.menu-mobil-box.open ul.menu li:nth-child(7) a {
  -webkit-transition: all 1.05s;
  -o-transition: all 1.05s;
  transition: all 1.05s;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.menu-mobil-box.open ul.menu li:nth-child(8) a {
  -webkit-transition: all 1.2s;
  -o-transition: all 1.2s;
  transition: all 1.2s;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.menu-mobil-box.open ul.menu li:nth-child(9) a {
  -webkit-transition: all 1.35s;
  -o-transition: all 1.35s;
  transition: all 1.35s;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.menu-mobil-box.open ul.menu.menu-secondary {
  opacity: 1;
}

footer.main-footer {
  background-color: #224878;
  color: #8C8C8C;
  font-weight: 500;
  bottom: 0;
}

footer.main-footer a:hover {
  color: #224878;
  text-decoration: none;
}

footer.main-footer .container {
  max-width: 1280px;
  width: 100%;
  padding: 0;
}

footer.main-footer p {
  padding-top: 4px;
}

footer.main-footer .footer-content .jnq-logo {
  opacity: 0.8;
}

footer.main-footer .footer-content .jnq-logo:hover {
  opacity: 1;
}

footer.main-footer .footer-content.part1 {
  border-right: solid 1px #193558;
}

footer.main-footer .footer-content.part1 .part-box {
  float: right;
}

footer.main-footer .footer-content .logo img {
  margin-bottom: 7px;
  margin-right: 10px;
  max-width: 70px;
  display: inline-block;
}

footer.main-footer .footer-content .text-info {
  display: inline;
  color: rgba(255, 255, 255, 0.4);
  line-height: 35px;
  padding-right: 5px;
  font-size: 1.5em;
}

footer.main-footer .footer-content .social-links {
  float: left;
  list-style: none;
  padding: 0;
  margin: 0;
}

footer.main-footer .footer-content .social-links li {
  display: inline-block;
  font-size: 2em;
  margin: 0px 10px;
  line-height: 35px;
}

footer.main-footer .footer-content .social-links li a {
  color: rgba(255, 255, 255, 0.6);
}

footer.main-footer .footer-content .social-links li a:hover {
  color: #fff;
}

footer.main-footer .footer-rights a {
  color: #4C433B;
}

footer.main-footer .footer-rights a:hover {
  color: #224878;
}

.nuestras-obras-page-class footer.main-footer .footer-content .social-links {
  margin-top: 10px;
}

.custom-modal {
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 999999999999;
  overflow: hidden !important;
}

.custom-modal .button {
  margin: 0;
}

.custom-modal .modal-dialog {
  background-color: #fff;
  display: inline-block;
  margin: 0;
  position: relative;
  top: 50%;
  transform: translateY(100%);
  -ms-transform: translateY(100%);
  -webkit-transform: translateY(100%);
}

.custom-modal .modal-dialog .modal-content {
  background-color: transparent;
  box-shadow: none;
  border: 0;
  position: static;
}

.custom-modal.in .modal-dialog {
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.custom-modal.user-dialogs .modal-dialog {
  width: 90%;
  max-width: 450px;
  padding: 40px;
  text-align: center;
}

.custom-modal.user-dialogs .modal-dialog .subtitle-section {
  margin-bottom: 10px;
}

.custom-modal.user-dialogs .modal-dialog .subtitle-section h3 {
  color: #2B2B2B;
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  background-color: #fff;
  padding: 10px;
  font-size: 1.6em;
}

.custom-modal.user-dialogs .modal-dialog .subtitle-section hr {
  border-color: #A5A9AD;
}

.custom-modal.user-dialogs .modal-dialog .modal-footer {
  margin-top: 20px;
  padding: 0;
  border: 0;
  text-align: center;
}

.custom-modal.user-dialogs .modal-dialog .modal-footer h4 {
  font-size: 1.4em;
  margin-bottom: 0;
  color: #EDEDED;
  margin-top: 10px;
}

.custom-modal.user-dialogs .modal-dialog .modal-footer h4 a {
  color: #224878;
}

.custom-modal.user-dialogs .modal-dialog .section-group {
  margin-bottom: 30px;
}

.custom-modal.user-dialogs .modal-dialog .form-field-box.form-field-box-md {
  margin-bottom: 30px;
  margin-top: 20px;
}

.custom-modal.user-dialogs .modal-dialog .form-field-box.form-field-box-md:first-of-type {
  margin-top: 0;
}

.custom-modal.user-dialogs .modal-dialog .social-buttons .button {
  padding: 15px 45px;
  margin: 5px;
}

.custom-modal.user-dialogs .modal-dialog .social-buttons .button.button-fb {
  background-color: #38569C;
}

.custom-modal.user-dialogs .modal-dialog .social-buttons .button.button-google {
  background-color: #EB534A;
}

.custom-modal.user-dialogs .modal-close.x {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  color: #A5A9AD;
}

.custom-modal.user-dialogs .modal-back {
  position: absolute;
  left: 20px;
  top: 20px;
  display: inline-block;
}

.custom-modal.user-dialogs .button-icon {
  color: #224878;
  font-weight: bold;
}

.custom-modal.user-dialogs .button-icon:hover {
  color: #fff;
}

.custom-modal.user-dialogs .title-section {
  margin-bottom: 20px;
}

.custom-modal.user-dialogs .title-section h1 {
  font-family: "Klavika";
  margin-bottom: 10px;
}

.custom-modal.user-dialogs .title-section p {
  color: #8C8C8C;
}

.custom-modal.user-dialogs .title-section a {
  font-weight: bold;
  color: #4C433B;
}

.custom-modal.user-dialogs .button {
  margin-bottom: 10px;
}

.custom-modal.user-dialogs .link {
  color: #224878;
  font-size: 1.4em;
  font-weight: bold;
}

.modal-backdrop {
  z-index: 99999999;
  background-color: #224878;
}

.modal-backdrop.fade.in {
  opacity: 0.9;
}

.custom-modal.user-dialogs.forget-password-a .modal-dialog {
  padding: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.custom-modal.user-dialogs.forget-password-a .modal-dialog .modal-content .title-section {
  margin-bottom: 0;
}

.custom-modal.user-dialogs.forget-password-a .modal-dialog .modal-content .title-section h2 {
  padding-top: 30px;
}

.custom-modal.user-dialogs.forget-password-b .modal-dialog {
  padding: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.custom-modal.user-dialogs.forget-password-b .modal-dialog .modal-content .title-section {
  margin-bottom: 0;
}

.custom-modal.user-dialogs.forget-password-b .modal-dialog .modal-content .title-section h2 {
  padding-top: 30px;
}

.custom-modal.user-dialogs.reset-password-validate .modal-dialog {
  padding: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.custom-modal.user-dialogs.reset-password-validate .modal-dialog .modal-content .title-section {
  margin-bottom: 0;
}

.custom-modal.user-dialogs.reset-password-validate .modal-dialog .modal-content .title-section h2 {
  padding-top: 30px;
}

.custom-modal.user-dialogs.reset-password-validate .modal-dialog .modal-content .title-section i.fa.fa-check {
  color: #224878;
  font-size: 30px;
}

.modal .registro-step-1 {
  transition: opacity 0.5s;
}

.modal .registro-step-2 {
  transition: opacity 0.5s;
  opacity: 0;
}

.custom-modal.modal-mensajes .modal-dialog {
  padding: 40px;
}

.custom-modal.modal-mensajes .modal-dialog h1 {
  color: #224878;
}

.custom-modal.modal-mensajes .modal-dialog h3 {
  color: #4C433B;
}

.modal.modal-gallery .modal-dialog {
  max-width: 800px;
  background-color: transparent;
}

.modal.modal-gallery .modal-dialog img.gallery-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 600px;
  min-width: 600px;
}

.modal.modal-gallery .modal-dialog .modal-close {
  position: absolute;
  right: -7px;
  top: -10px;
  padding: 3px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
}

.modal.modal-gallery .modal-dialog .modal-close img {
  width: 25px;
}

.custom-modal.video-modal .modal-dialog {
  background-color: #1e1e1e;
  width: 100%;
}

.custom-modal.video-modal .modal-dialog iframe {
  width: 100%;
  height: 100vh;
  position: relative;
}

.custom-modal.video-modal .modal-dialog .loader {
  position: absolute;
  width: 50px;
  left: 50%;
  margin-left: -25px;
}

.custom-modal.video-modal .modal-close {
  position: absolute;
  width: 50px;
  line-height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
  top: 50px;
  right: 50px;
  font-size: 2.8em;
  cursor: pointer;
}

.flash-message {
  float: left;
  bottom: 0;
  width: 100%;
  background-color: #2B2B2B;
  padding: 15px 30px;
  position: relative;
  z-index: 99999;
  transition: all 0.5s;
}

.flash-message.fixed {
  position: fixed;
}

.flash-message.top {
  top: 0;
  bottom: inherit;
}

.flash-message.hidden-flash {
  bottom: -87px;
}

.flash-message h4 {
  color: #fff;
  font-weight: 700;
  margin: 0;
  display: inline-block;
}

.flash-message h4 span {
  font-weight: 300;
}

.flash-message h4 i {
  padding-left: 20px;
}

.flash-message a.close {
  position: absolute;
  right: 0;
  line-height: 10px;
  float: right;
  color: #EDEDED;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  padding-right: 15px;
  top: 50%;
  margin-top: -10px;
}

.flash-message.success {
  background-color: #2B2B2B;
}

.flash-message.success h4 i {
  color: #00FF80;
}

.flash-message.error {
  background-color: #224878;
}

.flash-message.error h4 i {
  display: none;
}

.alert {
  position: fixed;
  bottom: 0;
  margin: 0;
  z-index: 9999999;
  width: 100%;
  left: 0;
  border-radius: 0;
  text-align: center;
  font-size: 1.6em;
  border: 0;
  color: #fff;
  line-height: normal;
  padding-bottom: 12px;
}

.alert i {
  margin-left: 10px;
}

.alert.alert-success {
  background-color: #2ecc71;
}

.alert.alert-danger {
  background-color: #e74c3c;
}

.alert button.close {
  line-height: normal;
  position: absolute;
  right: 15px;
  top: 8px;
}

table.bootstrap-table {
  font-size: 1.3em;
}

table.bootstrap-table * {
  border-top: 0 !important;
}

table.bootstrap-table tr th {
  padding: 10px 20px !important;
  color: #8C8C8C;
}

table.bootstrap-table tr td {
  padding: 10px 20px !important;
  color: #8C8C8C;
}

tr:nth-child(even) {
  background-color: rgba(165, 169, 173, 0.1);
}

.text-xs-left {
  text-align: left;
}

.text-xs-right {
  text-align: right;
}

.text-xs-center {
  text-align: center;
}

.text-xs-justify {
  text-align: justify;
}

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
  .text-sm-justify {
    text-align: justify;
  }
}

@media (min-width: 992px) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
  .text-md-justify {
    text-align: justify;
  }
}

@media (min-width: 1360px) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
  .text-lg-justify {
    text-align: justify;
  }
}

@media (min-width: 1560px) {
  .text-xl-left {
    text-align: left;
  }
  .text-xl-right {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
  .text-xl-justify {
    text-align: justify;
  }
}

.editable-container::before {
  content: "\f040";
  font: normal normal normal 14px/1 FontAwesome;
  width: 30px;
  line-height: 30px;
  background-color: #4a86e8;
  color: #fff;
  position: absolute;
  right: -15px;
  border-radius: 50%;
  top: -15px;
  z-index: 2;
  text-align: center;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.editable-container:hover::before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.editable-box {
  border: dashed 1px #a5a9ad;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  padding: 20px;
  background-color: transparent;
  resize: none;
  box-shadow: none;
}

.editable-box:hover {
  border-color: #4a86e8;
  border-style: solid;
}

.editable-box:hover .delete {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.editable-box.editable-box-dark {
  border-color: rgba(0, 0, 0, 0.25);
}

.editable-box.editable-box-dark:hover {
  border-color: #4a86e8;
}

.editable-box .delete {
  position: absolute;
  top: -15px;
  right: 20px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: red;
  color: #fff;
  text-align: center;
  font-size: 1.6em;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.table-box {
  display: table;
}

.table-cell {
  display: table-cell;
  float: none;
  vertical-align: top;
}

.tabla {
  border-collapse: collapse;
}

.tabla .table-cell {
  padding: 10px;
  background-color: #A5A9AD;
  border: solid 1px;
  border-color: #989ca1;
  vertical-align: middle;
}

.tabla .table-cell p {
  margin: 0;
}

.tabla.table-bg-dark .table-cell {
  background-color: transparent;
  border-color: #454545;
  border-width: 2px;
  margin-top: -2px;
}

.modal.custom-modal.modal-editar-front .modal-dialog {
  width: 90%;
  max-width: 1000px;
  border-radius: 4px;
}

.modal.custom-modal.modal-editar-front .modal-dialog hr {
  margin: 0;
}

.modal.custom-modal.modal-editar-front .modal-dialog .title-section {
  margin: 0;
}

.modal.custom-modal.modal-editar-front .modal-dialog .title-section .title {
  margin-bottom: 10px;
}

.modal.custom-modal.modal-editar-front .modal-dialog .title-section .subtitle {
  text-transform: none;
  margin: 0;
  font-weight: 400;
  font-size: 1.4em;
}

.modal.custom-modal.modal-editar-front .modal-dialog .footer-actions .button {
  padding: 10px 25px;
  font-size: 1.4em;
  font-weight: 500;
}

.modal.custom-modal.modal-editar-front .modal-dialog .content {
  padding-bottom: 20px;
  max-height: 600px;
  overflow-y: scroll;
}

.edit-slider-gallery-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.edit-slider-gallery-list li {
  display: inline-block;
  margin-left: -1px;
  margin-right: -1px;
  margin-bottom: 30px;
  cursor: move;
}

.edit-slider-gallery-list li .photo {
  height: 250px;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.edit-slider-gallery-list li .photo .button-eliminar {
  width: 30px;
  line-height: 30px;
  background-color: #224878;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 1.4em;
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
}

.edit-slider-gallery-list li .photo.upload-photo {
  cursor: pointer;
}

.edit-slider-gallery-list li .photo.upload-photo .overlay {
  border: solid 1px #EDEDED;
}

.edit-slider-gallery-list li .photo.upload-photo .overlay .icon {
  width: 120px;
  line-height: 120px;
  border-radius: 50%;
  background-color: #EDEDED;
  color: #fff;
  font-size: 4em;
  opacity: 0.5;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.edit-slider-gallery-list li .photo.upload-photo:hover .overlay .icon {
  opacity: 1;
}

.edit-slider-gallery-list li .photo .overlay {
  border-radius: 2px;
  background-position: center;
}

.edit-slider-gallery-list li .photo:hover {
  transform: translateY(-5%) scale(1.01);
}

.admin-bar {
  position: fixed;
  top: 0;
  background-color: #4C433B;
  font-weight: 500;
  z-index: 999;
}

.admin-bar .button {
  border-radius: 0;
  padding: 10px 20px;
}

.admin-bar .button.button-secondary {
  background-color: #2f2a25;
}

.admin-bar .button.button-primary {
  background-color: #224878;
}

.admin-bar .texto-bienvenida {
  color: #fff;
  font-size: 1.3em;
  padding: 10px;
}

#ui-datepicker-div.ui-widget.ui-widget-content {
  background-color: #fff;
  color: #4C433B;
  font-size: 1.2em;
  width: auto;
  padding: 0px;
  border: solid 2px #2B2B2B;
  border-top: 0px;
  border: 0px;
  box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.2);
  font-family: "Lato";
}

#ui-datepicker-div.ui-widget.ui-widget-content .ui-state-default {
  font-size: 1.2em;
  background-color: transparent;
  border: 0px;
  color: #8C8C8C;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: 500;
  padding: 0;
  border-radius: 2px;
}

#ui-datepicker-div.ui-widget.ui-widget-content .ui-state-default.ui-state-active {
  background-color: #224878;
  color: #fff;
}

#ui-datepicker-div.ui-widget.ui-widget-content .ui-state-default:hover {
  color: #224878;
  background-color: #EDEDED;
}

#ui-datepicker-div.ui-widget.ui-widget-content .ui-datepicker-header {
  border: 0px;
  border-radius: 0px;
  padding: 10px;
  background-color: #224878;
}

#ui-datepicker-div.ui-widget.ui-widget-content .ui-datepicker-header .ui-datepicker-title {
  color: #fff;
}

#ui-datepicker-div.ui-widget.ui-widget-content .ui-datepicker-header .ui-datepicker-title span {
  font-size: 1.4em;
}

#ui-datepicker-div.ui-widget.ui-widget-content table {
  margin: 0;
  width: 100%;
}

#ui-datepicker-div.ui-widget.ui-widget-content table th {
  text-align: center;
  color: #fff;
  background-color: #224878;
  padding: 10px;
}

#ui-datepicker-div.ui-widget.ui-widget-content table td {
  padding: 0;
}

#ui-datepicker-div.ui-widget.ui-widget-content .ui-datepicker-next, #ui-datepicker-div.ui-widget.ui-widget-content .ui-datepicker-prev {
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  border: 0;
  color: white;
  font-family: FontAwesome;
  text-align: center;
}

#ui-datepicker-div.ui-widget.ui-widget-content .ui-datepicker-next span, #ui-datepicker-div.ui-widget.ui-widget-content .ui-datepicker-prev span {
  display: none;
}

#ui-datepicker-div.ui-widget.ui-widget-content .ui-datepicker-next:hover, #ui-datepicker-div.ui-widget.ui-widget-content .ui-datepicker-prev:hover {
  background: none;
  border: 0;
}

#ui-datepicker-div.ui-widget.ui-widget-content .ui-datepicker-next:before {
  content: "\f0da";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  font-size: 1.4em;
}

#ui-datepicker-div.ui-widget.ui-widget-content .ui-datepicker-prev:before {
  content: "\f0d9";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  font-size: 1.4em;
}

.ui-datepicker .ui-datepicker-title select {
  border: 0px;
  -webkit-appearance: none;
  padding: 5px 15px;
  border-radius: 50px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.28);
}

#ui-datepicker-div.ui-widget.ui-widget-content.ui-datepicker-multi-2 {
  width: 560px !important;
}

#ui-datepicker-div .ui-datepicker-group .ui-datepicker-header {
  border-right: solid 1px rgba(0, 0, 0, 0.1) !important;
}

#ui-datepicker-div .ui-datepicker-group .ui-datepicker-calendar thead {
  background-color: #224878;
}

#ui-datepicker-div .ui-datepicker-group .ui-datepicker-calendar thead tr th:last-child {
  border-right: solid 1px rgba(0, 0, 0, 0.1) !important;
  background-color: transparent;
}

#ui-datepicker-div .ui-datepicker-group .ui-datepicker-calendar tbody {
  border-right: solid 1px #EDEDED;
}

.checkbox-causa {
  text-align: center;
  display: inline-block;
  margin: 20px;
  cursor: pointer;
}

.checkbox-causa input {
  display: none;
}

.checkbox-causa input:checked + label i {
  background-color: #2B2B2B;
  border-color: #2B2B2B;
  color: #fff;
}

.checkbox-causa input:checked + label span {
  color: #2B2B2B;
}

.checkbox-causa i {
  width: 80px;
  line-height: 80px;
  border-radius: 50%;
  border: solid 1px #EDEDED;
  font-size: 3.6em;
  color: #4C433B;
  text-align: center;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  margin-bottom: 10px;
  cursor: pointer;
}

.checkbox-causa i:hover {
  background-color: #EDEDED;
}

.checkbox-causa span {
  display: block;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
  font-size: 1.4em;
  color: #8C8C8C;
  font-weight: 500;
}

.tabs-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
}

.tabs-menu li {
  display: inline-block;
  float: left;
}

.tabs-menu li .link {
  display: inline-block;
  font-size: 1.4em;
  font-weight: 500;
  padding: 15px 0px;
  margin: 0;
  margin-right: 23px;
  color: #8C8C8C;
  text-decoration: none;
  cursor: pointer;
  border-bottom: solid 4px transparent;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.tabs-menu li .link span {
  color: #4C433B;
}

.tabs-menu li .link:hover {
  color: #4C433B;
}

.tabs-menu li.active a {
  border-bottom: solid 4px #224878;
  color: #224878;
}

.tabs-menu li .checkbox {
  display: none;
}

.tabs-menu li .checkbox:checked + .link {
  border-bottom-color: #224878;
  color: #224878;
}

.ios-checkbox {
  display: inline-block;
}

.ios-checkbox label {
  cursor: pointer;
}

.ios-checkbox i {
  width: 25px;
  height: 15px;
  border-radius: 15px;
  background-color: #EDEDED;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  transition: all 0.15s;
}

.ios-checkbox i .circle {
  width: 15px;
  height: 15px;
  background-color: #fff;
  position: absolute;
  left: 0;
  border: solid 1px #EDEDED;
  border-radius: 50%;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  transition: all 0.15s;
}

.ios-checkbox .text {
  font-size: 1.8em;
  vertical-align: bottom;
  color: #8C8C8C;
}

.ios-checkbox input {
  display: none;
}

.ios-checkbox input:checked + label i {
  background-color: #77d118;
}

.ios-checkbox input:checked + label i .circle {
  left: 15px;
  border-color: #77d118;
}

.ios-checkbox input:checked + label .text {
  color: #2B2B2B;
}

.circle {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.p_fadeout {
  opacity: 0;
}

.p_fadein {
  opacity: 1;
}

.button-user-action {
  border-radius: 2px;
  background-color: #4C433B;
  color: #fff;
  padding: 11px;
  line-height: 20px;
}

.button-user-action:hover {
  color: #fff;
  background-color: #5a5046;
}

.button-user-action .button-user-option-box {
  display: inline-block;
}

.button-user-action .button-user-text {
  display: none;
}

.button-user-action .action-option-field {
  display: none;
}

.button-user-action .action-option-field:checked + .button-user-text {
  display: inline-block;
}

.submenu-user-actions-box {
  position: relative;
  display: inline-block;
}

.submenu-user-actions {
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  border: solid 1px #EDEDED;
  background-color: #fff;
  z-index: 4;
  margin-top: 10px;
  right: 0;
  display: none;
  top: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 100% 0%;
  -moz-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -o-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  -webkit-transition: transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
}

.submenu-user-actions.submenu-user-actions-open {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.submenu-user-actions:before {
  content: "\f0d8";
  color: #fff;
  text-shadow: 0px -2px 0px #EDEDED;
  position: absolute;
  font-family: 'FontAwesome';
  right: 10px;
  top: -9px;
  font-size: 28px;
  line-height: 10px;
}

.submenu-user-actions li {
  display: inline-block;
  width: 100%;
  border-bottom: solid 1px #EDEDED;
  position: relative;
  z-index: 2;
}

.submenu-user-actions .submenu-user-option {
  padding: 10px;
  display: inline-block;
  width: 100%;
  font-size: 1.2em;
  color: #8C8C8C;
  cursor: pointer;
  margin: 0;
}

.submenu-user-actions .submenu-user-option .action-icon {
  margin-right: 10px;
}

.submenu-user-actions .submenu-user-option:hover {
  color: #4C433B;
  text-decoration: none;
}

.submenu-user-actions .action-option-field {
  display: none;
}

.submenu-user-actions .action-option-field:checked + .submenu-user-option {
  background-color: #EDEDED;
  color: #4C433B;
}

.submenu-user-actions .submenu-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.submenu-user-actions .submenu-header .title {
  font-size: 1.4em;
  font-weight: bold;
}

.submenu-user-actions .submenu-header a.link {
  padding: 0;
  color: #fff !important;
  text-decoration: underline;
}

.submenu-notifications-box .notification-list-content {
  padding: 0;
  margin: 0;
  float: left;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  width: 100%;
}

.submenu-notifications-box .submenu-user-actions {
  border: 0px;
  background-color: transparent;
}

.submenu-notifications-box .submenu-user-actions:before {
  color: #224878;
  text-shadow: none;
}

.submenu-notifications-box .single-notification {
  font-size: 10px;
  width: 100%;
}

.submenu-notifications-box .single-notification a.submenu-user-option {
  font-size: 10px;
  padding: 10px;
  padding-left: 70px;
  padding-right: 60px;
  min-height: 74px;
  background-color: #d2d4d6;
  width: 100%;
}

.submenu-notifications-box .single-notification a.submenu-user-option:hover {
  background-color: #d2d4d6;
}

.submenu-notifications-box .single-notification .photo {
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 50%;
  background-color: #A5A9AD;
  position: absolute;
  left: 10px;
}

.submenu-notifications-box .single-notification .name {
  font-size: 14px;
  font-weight: 600;
  color: #4C433B;
  width: 100%;
  margin: 0;
  display: inline-block;
}

.submenu-notifications-box .single-notification .text {
  font-size: 12px;
  font-weight: 600;
  color: #8C8C8C;
  width: 100%;
}

.submenu-notifications-box .single-notification .time {
  position: absolute;
  right: 15px;
  top: 30px;
  color: #4C433B;
}

.submenu-notifications-box .single-notification.view-all .submenu-user-option {
  padding: 10px;
  font-size: 14px;
  min-height: 0;
}

.submenu-notifications-box .single-notification.view-all .submenu-user-option:hover {
  background-color: #fff;
}

.submenu-notifications-box .single-notification.status-unreaded a.submenu-user-option {
  background-color: #fff;
}

.submenu-notifications-box .single-notification.status-unreaded a.submenu-user-option .name {
  color: #224878;
}

.submenu-notifications-box .single-notification.status-unreaded a.submenu-user-option .photo:before {
  content: '.';
  color: transparent;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #224878;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.submenu-notifications-box .empty-notifications .submenu-user-option {
  cursor: auto;
  background-color: #fff;
}

.submenu-notifications-chat-box .notification-list-content {
  padding: 0;
  margin: 0;
  float: left;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;
}

.submenu-notifications-chat-box .submenu-user-actions {
  border: 0px;
  background-color: transparent;
}

.submenu-notifications-chat-box .submenu-user-actions:before {
  color: #224878;
  text-shadow: none;
}

.submenu-notifications-chat-box .single-notification {
  font-size: 10px;
}

.submenu-notifications-chat-box .single-notification a .notification-icon {
  position: absolute;
  top: 0;
  right: -5px;
  color: #fff;
  background-color: #4C433B;
  width: 22px;
  line-height: 22px;
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
}

.submenu-notifications-chat-box .single-notification a .notification-icon.active {
  background-color: #224878;
}

.submenu-notifications-chat-box .single-notification a.submenu-user-option {
  font-size: 10px;
  padding: 10px;
  padding-left: 70px;
  padding-right: 60px;
  min-height: 74px;
  background-color: #fff;
}

.submenu-notifications-chat-box .single-notification a.submenu-user-option:hover {
  background-color: #EDEDED;
}

.submenu-notifications-chat-box .single-notification .photo {
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 50%;
  background-color: #A5A9AD;
  position: absolute;
  left: 10px;
}

.submenu-notifications-chat-box .single-notification .name {
  font-size: 14px;
  font-weight: 600;
  color: #4C433B;
  width: 100%;
  margin: 0;
  display: inline-block;
}

.submenu-notifications-chat-box .single-notification .text {
  font-size: 12px;
  font-weight: 600;
  color: #8C8C8C;
  width: 100%;
}

.submenu-notifications-chat-box .single-notification .time {
  position: absolute;
  right: 15px;
  top: 30px;
  color: #4C433B;
}

.submenu-notifications-chat-box .single-notification.view-all .submenu-user-option {
  padding: 10px;
  font-size: 14px;
  min-height: 0;
}

.submenu-notifications-chat-box .single-notification.view-all .submenu-user-option:hover {
  background-color: #fff;
}

.submenu-notifications-chat-box .empty-notifications .submenu-user-option {
  cursor: auto;
  background-color: #fff;
}

@media all and (min-width: 769px) {
  body::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }
  body::-webkit-scrollbar-track {
    background-color: #2B2B2B;
  }
  body::-webkit-scrollbar-thumb {
    background-color: #224878;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #224878;
  }
}

.fancybox-opened {
  z-index: 99999999;
}

.fancybox-overlay {
  z-index: 9999999;
  background: rgba(43, 43, 43, 0.85);
}

.fancybox-overlay .fancybox-opened {
  z-index: 9999999;
}

.fancybox-overlay .fancybox-opened .fancybox-skin {
  box-shadow: none;
}

.fancybox-overlay .fancybox-wrap .fancybox-skin {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

html.fancybox-margin .fancybox-overlay .fancybox-wrap .fancybox-skin {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.fancybox-title-float-wrap {
  margin: 0;
  right: auto;
  left: 0;
  width: 100%;
  text-align: left;
}

.fancybox-title-float-wrap .child {
  margin: 0;
  width: 100%;
  background-color: #2B2B2B;
  border-radius: 0;
  word-wrap: break-word;
  white-space: normal;
  padding: 5px 10px;
  font-family: "Lato";
  color: #8C8C8C;
  font-weight: 500;
  text-shadow: none;
}

.fancybox-title-float-wrap:hover .child {
  background-color: #2B2B2B;
  color: #fff;
}

.form-add-gallery {
  list-style: none;
  padding: 0;
  margin: 0;
}

.form-add-gallery li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: top;
  position: relative;
}

.form-add-gallery li input {
  display: none;
}

.form-add-gallery .add-image-block {
  width: 400px;
  height: 400px;
  display: inline-block;
  position: relative;
}

.form-add-gallery .add-image-button {
  background-color: #4C433B;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.form-add-gallery .delete {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  line-height: 30px;
  background-color: #4C433B;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.form-add-gallery .delete:hover {
  background-color: #224878;
}

.cropControls.cropControlsUpload .cropControlRemoveCroppedImage {
  position: absolute;
  right: 1px;
  top: 1px;
  background-color: #4C433B;
  background: none;
  display: inline-block;
  width: auto;
  height: auto;
}

.cropControls.cropControlsUpload .cropControlRemoveCroppedImage:before {
  content: "\f00d";
  font-family: FontAwesome;
  color: transparent;
  display: inline-block;
  color: #fff;
  background-color: #224878;
  width: 30px;
  line-height: 30px;
}

#croppicModal {
  background-color: rgba(43, 43, 43, 0.98);
  z-index: 10000000;
}

#croppicModal img {
  opacity: 0.2 !important;
  background-color: #2B2B2B;
  -webkit-filter: blur(5px);
  -o-filter: blur(5px);
  filter: blur(5px);
}

#croppicModal .cropImgWrapper img {
  opacity: 1 !important;
  -webkit-filter: none;
  -o-filter: none;
  filter: none;
}

#croppicModal .cropControls.cropControlsCrop {
  position: fixed;
  top: auto;
  bottom: 0;
  background-color: #2B2B2B;
  width: 100%;
  text-align: center;
  height: auto;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 8px;
}

#croppicModal .cropControls.cropControlsCrop i {
  display: inline-block;
  float: none;
  font-family: "Lato";
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  background-color: #4C433B;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  background-size: 20px auto;
}

#croppicModal .cropControls.cropControlsCrop i:hover {
  background-color: rgba(76, 67, 59, 0.5);
}

#croppicModal .cropControls.cropControlsCrop .cropControlCrop {
  float: left;
  width: auto;
  background: none;
  padding: 20px 30px;
  line-height: 20px;
  font-size: 16px;
  height: auto;
  border-right: solid 1px #4C433B;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0;
  margin: 0;
}

#croppicModal .cropControls.cropControlsCrop .cropControlCrop::before {
  content: '\f046';
  color: #77d118;
  font-family: FontAwesome;
  display: inline-block;
  margin-right: 10px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

#croppicModal .cropControls.cropControlsCrop .cropControlCrop::after {
  content: 'Save Image';
}

#croppicModal .cropControls.cropControlsCrop .cropControlCrop:hover::before {
  margin-right: 20px;
}

#croppicModal .cropControls.cropControlsCrop .cropControlReset {
  float: left;
  background: none;
  padding: 20px 30px;
  border-left: solid 1px #4C433B;
  font-size: 16px;
  width: auto;
  height: auto;
  position: absolute;
  right: 0px;
  top: 0;
  border-radius: 0;
  margin: 0;
}

#croppicModal .cropControls.cropControlsCrop .cropControlReset::before {
  content: "\f00d";
  color: #224878;
  font-family: FontAwesome;
  display: inline-block;
  margin-right: 10px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

#croppicModal .cropControls.cropControlsCrop .cropControlReset::after {
  content: 'Cancel';
  display: inline-block;
  opacity: 0.5;
}

#croppicModal .cropControls.cropControlsCrop .cropControlReset:hover {
  background-color: rgba(76, 67, 59, 0.25);
}

#croppicModal .cropControls.cropControlsCrop .cropControlReset:hover::before {
  margin-right: 20px;
}

#croppicModal .cropControlZoomMuchIn {
  background-image: url("/assets/online/base/img/croppic/zoom-plus.png");
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 30px;
}

#croppicModal .cropControlZoomMuchIn:hover {
  background-image: url("/assets/online/base/img/croppic/zoom-plus-hover.png");
}

#croppicModal .cropControlZoomIn {
  background-image: url("/assets/online/base/img/croppic/zoom.png");
}

#croppicModal .cropControlZoomIn:hover {
  background-image: url("/assets/online/base/img/croppic/zoom-hover.png");
}

#croppicModal .cropControlZoomOut {
  background-image: url("/assets/online/base/img/croppic/zoom-menus.png");
}

#croppicModal .cropControlZoomOut:hover {
  background-image: url("/assets/online/base/img/croppic/zoom-menus-hover.png");
}

#croppicModal .cropControlZoomMuchOut {
  background-image: url("/assets/online/base/img/croppic/zoom-menus-plus.png");
}

#croppicModal .cropControlZoomMuchOut:hover {
  background-image: url("/assets/online/base/img/croppic/zoom-menus-plus-hover.png");
}

#croppicModal .cropControlRotateLeft {
  background-image: url("/assets/online/base/img/croppic/rotate-left.png");
}

#croppicModal .cropControlRotateLeft:hover {
  background-image: url("/assets/online/base/img/croppic/rotate-left-hover.png");
}

#croppicModal .cropControlRotateRight {
  background-image: url("/assets/online/base/img/croppic/rotate-right.png");
  padding-right: 30px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

#croppicModal .cropControlRotateRight:hover {
  background-image: url("/assets/online/base/img/croppic/rotate-right-hover.png");
}

.add-image-block .cropControlsUpload {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.add-image-block .cropControlsUpload:hover {
  background-color: transparent;
}

.add-image-block .cropControlsUpload .cropControlUpload {
  width: 100%;
  height: 100%;
  background-image: url("/assets/online/base/img/croppic/upload.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px auto;
  opacity: 0.75;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.add-image-block .cropControlsUpload .cropControlUpload:hover {
  opacity: 1;
  background-color: transparent;
  background-size: 55px auto;
}

.croppedImg {
  width: 100%;
}

.croppedImg + .cropControls.cropControlsUpload .cropControlUpload {
  background: none;
  display: inline-block;
  position: absolute;
  right: 1px;
  top: 1px;
  opacity: 1;
}

.croppedImg + .cropControls.cropControlsUpload .cropControlUpload:before {
  content: "\f093";
  font-family: FontAwesome;
  width: 30px;
  line-height: 30px;
  background-color: rgba(119, 209, 24, 0.75);
  display: inline-block;
  position: absolute;
  top: 0;
  right: 30px;
}

.croppedImg + .cropControls.cropControlsUpload .cropControlUpload:hover:before {
  background-color: #77d118;
}

.tooltip {
  font-family: "Lato";
  font-weight: 500;
}

.tooltip.in {
  opacity: 1;
}

.tooltip .tooltip-inner {
  background-color: #224878;
  max-width: 300px;
  font-size: 1.2em;
  padding: 5px 10px;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #224878;
}

.sr-only, .bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after, .bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after, .bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after, .bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after, .bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after, .bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after, .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after, .bootstrap-datetimepicker-widget .btn[data-action="clear"]::after, .bootstrap-datetimepicker-widget .btn[data-action="today"]::after, .bootstrap-datetimepicker-widget .picker-switch::after, .bootstrap-datetimepicker-widget table th.prev::after, .bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*!
 * Datetimepicker for Bootstrap 3
 * ! version : 4.7.14
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.bootstrap-datetimepicker-widget {
  list-style: none;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  margin: 2px 0;
  padding: 4px;
  width: 19em;
}

@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}

@media (min-width: 992px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}

@media (min-width: 1360px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}

.bootstrap-datetimepicker-widget.dropdown-menu:before, .bootstrap-datetimepicker-widget.dropdown-menu:after {
  content: '';
  display: inline-block;
  position: absolute;
}

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #A5A9AD;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  top: -7px;
  left: 7px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  left: 8px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #A5A9AD;
  border-top-color: rgba(0, 0, 0, 0.2);
  bottom: -7px;
  left: 6px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  bottom: -6px;
  left: 7px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px;
}

.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0;
}

.bootstrap-datetimepicker-widget a[data-action] {
  padding: 6px 0;
}

.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none;
}

.bootstrap-datetimepicker-widget .timepicker-hour, .bootstrap-datetimepicker-widget .timepicker-minute, .bootstrap-datetimepicker-widget .timepicker-second {
  width: 54px;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0;
}

.bootstrap-datetimepicker-widget button[data-action] {
  padding: 6px;
}

.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  content: "Increment Hours";
}

.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  content: "Increment Minutes";
}

.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  content: "Decrement Hours";
}

.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  content: "Decrement Minutes";
}

.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  content: "Show Hours";
}

.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  content: "Show Minutes";
}

.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  content: "Toggle AM/PM";
}

.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  content: "Clear the picker";
}

.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  content: "Set the date to today";
}

.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
}

.bootstrap-datetimepicker-widget .picker-switch::after {
  content: "Toggle Date and Time Screens";
}

.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}

.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
}

.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
}

.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
  text-align: center;
  border-radius: 2px;
}

.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
}

.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
}

.bootstrap-datetimepicker-widget table th.disabled, .bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #EDEDED;
  cursor: not-allowed;
}

.bootstrap-datetimepicker-widget table th.prev::after {
  content: "Previous Month";
}

.bootstrap-datetimepicker-widget table th.next::after {
  content: "Next Month";
}

.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: #EDEDED;
}

.bootstrap-datetimepicker-widget table td {
  height: 54px;
  line-height: 54px;
  width: 54px;
}

.bootstrap-datetimepicker-widget table td.cw {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #EDEDED;
}

.bootstrap-datetimepicker-widget table td.day {
  height: 20px;
  line-height: 20px;
  width: 20px;
}

.bootstrap-datetimepicker-widget table td.day:hover, .bootstrap-datetimepicker-widget table td.hour:hover, .bootstrap-datetimepicker-widget table td.minute:hover, .bootstrap-datetimepicker-widget table td.second:hover {
  background: #EDEDED;
  cursor: pointer;
}

.bootstrap-datetimepicker-widget table td.old, .bootstrap-datetimepicker-widget table td.new {
  color: #EDEDED;
}

.bootstrap-datetimepicker-widget table td.today {
  position: relative;
}

.bootstrap-datetimepicker-widget table td.today:before {
  content: '';
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #224878;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #224878;
  color: #fff;
  text-shadow: none;
}

.bootstrap-datetimepicker-widget table td.active.today:before {
  border-bottom-color: #fff;
}

.bootstrap-datetimepicker-widget table td.disabled, .bootstrap-datetimepicker-widget table td.disabled:hover {
  background: none;
  color: #EDEDED;
  cursor: not-allowed;
}

.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 54px;
  margin: 2px 1.5px;
  cursor: pointer;
  border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td span:hover {
  background: #EDEDED;
}

.bootstrap-datetimepicker-widget table td span.active {
  background-color: #224878;
  color: #fff;
  text-shadow: none;
}

.bootstrap-datetimepicker-widget table td span.old {
  color: #EDEDED;
}

.bootstrap-datetimepicker-widget table td span.disabled, .bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #EDEDED;
  cursor: not-allowed;
}

.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px;
}

.input-group.date .input-group-addon {
  cursor: pointer;
}

.cloud-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cloud-list .item {
  display: inline-block;
  margin-right: 1px;
  margin-bottom: 3px;
  position: relative;
}

.cloud-list .item .input-check {
  display: none;
}

.cloud-list .item .input-check:checked + .text {
  background-color: #224878;
  color: #fff;
  display: inline-block !important;
}

.cloud-list .item .text {
  background-color: #EDEDED;
  margin: 0;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 2px;
  color: #8C8C8C;
  font-size: 1.4em;
  font-weight: 600;
}

.cloud-list .item .text .icon {
  margin-right: 5px;
}

.cloud-list .item label.text {
  cursor: pointer;
}

.cloud-list .item .delete {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  line-height: 20px;
  background-color: #4C433B;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  cursor: pointer;
}

.cloud-list .item:hover .delete {
  opacity: 1;
}

.loader-bar {
  position: fixed;
  top: 0;
  left: 50%;
  width: 0%;
  height: 2px;
  z-index: 999999999;
  background-color: #224878;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
}

.number-pagination-box {
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
}

.number-pagination-box li {
  display: inline-block;
}

.number-pagination-box li .pagination-link {
  display: inline-block;
  background-color: #EDEDED;
  padding: 10px;
  color: #8C8C8C;
  text-decoration: none;
  font-size: 1.4em;
}

.number-pagination-box li .pagination-link:hover {
  background-color: #A5A9AD;
  color: #fff;
}

.number-pagination-box li .pagination-link.active {
  background-color: #224878;
  color: #fff;
}

.accordion-box {
  border-radius: 0px;
  overflow: hidden;
}

.accordion-box .accordion-single {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  margin-bottom: 5px;
  border-bottom: solid 2px #EDEDED;
}

.accordion-box .accordion-single.active .accordion-header .accordion-header-link {
  background-color: #fff;
  color: #224878;
}

.accordion-box .accordion-single.active .accordion-header .accordion-header-link .icon {
  background-color: #224878;
  color: #fff;
  -webkit-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
  -webkit-transition: all 0.25s;
  -ms-transition: all 0.25s;
  transition: all 0.25s;
}

.accordion-box .accordion-header .accordion-header-link {
  padding: 20px 15px;
  background-color: #fff;
  display: inline-block;
  width: 100%;
  font-size: 1.6em;
  font-weight: bold;
  color: #8C8C8C;
  font-family: "Klavika";
  position: relative;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.accordion-box .accordion-header .accordion-header-link .icon {
  color: #8C8C8C;
  position: absolute;
  right: 20px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: #EDEDED;
  color: #fff;
  text-align: center;
}

.accordion-box .accordion-body-box {
  background-color: #fff;
}

.accordion-box .accordion-body-box .form-field-box .form-field {
  border: 0px;
  background-color: #f7f7f7;
}

.jquery-ui-slider {
  background-color: #EDEDED;
}

.jquery-ui-slider .ui-slider-range {
  background-color: #224878;
}

.jquery-ui-slider .ui-slider-handle {
  border: 0;
  background: #254e82;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
}

.jui-slider-amount {
  border: 0;
  color: #224878;
  font-family: "Klavika";
  font-size: 1.4em;
  font-style: italic;
  padding: 0;
  padding-left: 10px;
}

.select-menu-box {
  margin-bottom: 20px;
}

.select-menu-box .form-field-box {
  margin: 0;
}

.select-menu-box .form-field-box .field-icon {
  background-color: transparent;
  color: #224878;
}

.select-menu-box .form-field-box .form-field {
  border-radius: 0;
  padding-left: 42px;
  border: 0;
}

.select-menu-box .button {
  padding: 10px;
  margin: 0;
}

.wizard-steps-box {
  list-style: none;
  padding: 0;
  margin: 0;
}

.wizard-steps-box .ws-item {
  padding: 10px 15px;
  background-color: #f7f7f7;
  vertical-align: top;
  min-height: 59px;
  border-bottom: solid 2px #EDEDED;
}

.wizard-steps-box .ws-item .title {
  font-family: "Klavika";
  font-size: 1.6em;
  margin-bottom: 5px;
  font-weight: 600;
}

.wizard-steps-box .ws-item .subtitle {
  font-size: 1.4em;
  color: #8C8C8C;
}

.wizard-steps-box .ws-item.active {
  background-color: #224878;
  border-color: #224878;
}

.wizard-steps-box .ws-item.active .title {
  color: #fff;
  padding-left: 25px;
  position: relative;
}

.wizard-steps-box .ws-item.active .title:before {
  content: "\f00c";
  font-family: FontAwesome;
  display: inline-block;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 0.6em;
  background-color: #77d118;
  position: absolute;
  left: 0;
  top: -2px;
}

.wizard-steps-box .ws-item.active .subtitle {
  color: #fff;
  opacity: 0.5;
}

.wizard-steps-box .ws-item.active + .ws-item {
  border-color: #224878;
}

.options-inline-box {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 5px;
}

.options-inline-box li {
  display: inline-block;
  float: left;
}

.options-inline-box li input {
  display: none;
}

.options-inline-box li input:checked + label {
  background-color: #224878;
  color: #fff;
}

.options-inline-box li label {
  display: inline-block;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #f7f7f7;
  font-size: 1.8em;
  cursor: pointer;
}

.options-inline-box li:first-child label {
  border-radius: 2px 0px 0px 2px;
}

.options-inline-box li:last-child label {
  border-radius: 0px 2px 2px 0px;
  margin-right: 5px;
}

.ms-options-wrap button {
  padding: 10px;
  line-height: 20px;
  font-size: 1.4em;
  font-family: "Lato";
  border: 0px;
  color: #4C433B;
}

.ms-options-wrap button:focus {
  padding: 10px;
  line-height: 20px;
  font-size: 1.4em;
  border: 0px;
  color: #4C433B;
}

.ms-options-wrap button::after {
  right: 20px;
}

.ms-options-wrap .ms-options {
  border: 0px;
}

.ms-options-wrap .ms-options .ms-search input {
  padding: 10px;
  font-size: 1.4em;
  line-height: 20px;
  color: #4C433B;
  border: 0;
  border-bottom: solid 1px #EDEDED;
}

.ms-options-wrap .ms-options ul li.ms-reflow label {
  text-align: left;
  padding: 10px;
  line-height: 20px;
  font-size: 1.4em;
  color: #4C433B;
  border: 0px;
  margin: 0;
}

.ms-options-wrap .ms-options ul li.ms-reflow label input {
  display: none;
}

.ms-options-wrap .ms-options ul li.ms-reflow.selected label {
  background-color: #224878;
  color: #fff;
}

.chat-box-main-container {
  position: fixed;
  position: fixed;
  bottom: 0;
  right: 5px;
  z-index: 9999;
  transition: all 0.45s;
  pointer-events: none;
}

.chat-options-btn {
  position: absolute;
  top: 10px;
  right: 13px;
  color: #fff;
  font-size: 1.4em;
  z-index: 4;
  opacity: 1;
}

.chat-options-btn .ios-checkbox i {
  margin: 0;
  background-color: rgba(76, 67, 59, 0.5);
}

.chat-options-btn .ios-checkbox i .circle {
  border-color: transparent;
}

.chat-options-btn.chatbox-close {
  opacity: 0.5;
  width: 35px;
  line-height: 35px;
  text-align: center;
  top: 0;
  right: 0;
  cursor: pointer;
}

.chat-options-btn.chatbox-close:hover {
  opacity: 1;
  color: #fff;
}

.chat-box {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 10px;
  position: relative;
  width: 300px;
  pointer-events: auto;
}

.chat-box.chat-active .chat-box-header {
  background-color: #224878;
}

.chat-box.chat-active .chat-box-header:hover {
  background-color: #224878;
}

.chat-box .chat-box-header {
  background-color: #4C433B;
  position: relative;
  color: #fff;
  display: inline-block;
  padding: 10px 15px;
  width: 100%;
  font-size: 1.4em;
  font-weight: 600;
  border-radius: 4px 4px 0px 0px;
  z-index: 3;
  cursor: pointer;
}

.chat-box .chat-box-header .chat-icon {
  margin-right: 5px;
}

.chat-box .chat-box-header label {
  margin: 0;
}

.chat-box .chat-box-header:hover {
  text-decoration: none;
  background-color: #5a5046;
}

.chat-box .chat-box-content {
  background-color: white;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-left: solid 1px #EDEDED;
  border-right: solid 1px #EDEDED;
  position: static;
}

.chat-box .chat-box-content.chat-box-content-messages {
  padding-bottom: 0px;
  margin-bottom: 35px;
}

.chat-box .chat-text-field {
  position: absolute;
  bottom: 0;
  margin: 0;
  z-index: 2;
}

.chat-box .chat-text-field .form-field {
  border: 0px;
  border-top: solid 1px #A5A9AD;
  background-color: #fff;
  resize: none;
  max-height: 100px;
  overflow-y: scroll !important;
  border-radius: 0;
  padding: 5px 10px;
  min-height: 35px;
  float: left;
  border-left: solid 1px #EDEDED;
  border-right: solid 1px #EDEDED;
  padding-right: 60px;
}

.chat-box .chat-text_message_render {
  border: 0px;
  z-index: 24;
  border-top: solid 1px #A5A9AD;
  background-color: #fff;
  display: inline-block;
  font-size: 1.4em;
  resize: none;
  max-height: 100px;
  overflow-y: scroll !important;
  border-radius: 0;
  padding: 5px 10px;
  min-height: 35px;
  float: left;
  border-left: solid 1px #EDEDED;
  border-right: solid 1px #EDEDED;
  width: 100%;
  word-wrap: break-word;
  position: absolute;
  bottom: 0;
  margin: 0;
  font-weight: 400;
  cursor: text;
  display: none;
}

.chat-box .chat-messages {
  list-style: none;
  padding: 0;
  margin: 0;
  position: static;
  z-index: 1;
  padding-bottom: 10px;
}

.chat-box .chat-messages .chat-single-message {
  padding: 35px;
  padding-top: 5px;
  padding-bottom: 0px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.chat-box .chat-messages .chat-single-message .photo-profile {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #EDEDED;
  display: inline-block;
}

.chat-box .chat-messages .chat-single-message .text {
  background-color: #EDEDED;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 1.4em;
  border-top-left-radius: 0;
  max-width: 85%;
  word-wrap: break-word;
  word-break: break-all;
}

.chat-box .chat-messages .chat-single-message .thumb-box {
  display: inline-block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  background-color: #2B2B2B;
  position: relative;
}

.chat-box .chat-messages .chat-single-message .thumb-box .thumb {
  background-color: #2B2B2B;
  display: inline-block;
  border-radius: 20px;
  border-top-left-radius: 0;
  width: 100%;
  height: 150px;
  float: left;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.chat-box .chat-messages .chat-single-message .thumb-box .thumb:hover {
  opacity: 0.5;
}

.chat-box .chat-messages .chat-single-message .thumb-box.thumb-video-box:after {
  content: "\f04b";
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  color: #fff;
  font-family: FontAwesome;
  font-size: 1.2em;
  pointer-events: none;
  width: 50px;
  line-height: 50px;
  border-radius: 50%;
  background-color: rgba(43, 43, 43, 0.5);
  display: inline-block;
}

.chat-box .chat-messages .chat-single-message.user-message {
  text-align: right;
}

.chat-box .chat-messages .chat-single-message.user-message .photo-profile {
  left: auto;
  right: 3px;
}

.chat-box .chat-messages .chat-single-message.user-message .text {
  background-color: #4C433B;
  color: #fff;
  border-radius: 20px;
  border-top-right-radius: 0;
}

.chat-box .chat-messages .chat-single-message.user-message .thumb-box .thumb {
  border-radius: 20px;
  border-top-right-radius: 0;
}

.chat-box .chat-messages .chat-message-date {
  text-align: center;
  color: #8C8C8C;
  border-bottom: solid 1px #EDEDED;
  height: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.chat-box .chat-messages .chat-message-date .date {
  padding: 0px 10px;
  background-color: #fff;
}

.chat-box.chat-box-group {
  width: auto;
}

.chat-box-single {
  margin-right: -300px;
  opacity: 0;
  transition: all 0.25s;
}

.chat-box-single.chat-box-open {
  margin-right: 5px;
  opacity: 1;
}

body.menu-open .chat-box-main-container {
  right: -290px;
}

.chat-list-box .chat-box-header {
  background-color: #224878;
}

.chat-list-box .chat-box-header:hover {
  background-color: #224878;
}

.chat-box-content.chat-list-users {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: white;
  float: left;
  border-left: solid 1px #EDEDED;
  border-right: solid 1px #EDEDED;
  height: 350px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.chat-box-content.chat-list-users .chat-single-user {
  padding: 10px;
  position: relative;
  font-size: 1.4em;
  width: 100%;
  float: left;
  cursor: pointer;
}

.chat-box-content.chat-list-users .chat-single-user .notification-icon {
  color: blue;
}

.chat-box-content.chat-list-users .chat-single-user:before {
  content: '|';
  position: absolute;
  left: 0;
  top: 50%;
  height: 0%;
  background-color: #224878;
  width: 2px;
  color: transparent;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.chat-box-content.chat-list-users .chat-single-user:hover {
  background-color: #EDEDED;
}

.chat-box-content.chat-list-users .chat-single-user:hover:before {
  height: 100%;
}

.chat-box-content.chat-list-users .chat-single-user .photo {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #2B2B2B;
  border-radius: 50%;
  margin-right: 8px;
}

.chat-box-content.chat-list-users .chat-single-user .name {
  margin-top: 5px;
  color: #8C8C8C;
}

.chat-box-content.chat-list-users .chat-single-user .status {
  display: inline-block;
  position: absolute;
  top: 23px;
  right: 15px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #A5A9AD;
}

.chat-box-content.chat-list-users .chat-single-user .status.online {
  background-color: #77d118;
}

.js-chat-single-container {
  display: inline-block;
  pointer-events: none;
}

.chat-box.chat-box-group .chat-list-users {
  width: 280px;
  height: auto;
  position: absolute;
  bottom: 50px;
  right: 0;
  display: none;
}

.chat-btn-action-box {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.chat-btn-action-box li {
  float: left;
  display: inline-block;
}

.chat-btn-action-box li .chat-btn-action {
  display: inline-block;
  width: 30px;
  line-height: 35px;
  text-align: center;
  font-size: 1.4em;
  color: #8C8C8C;
  cursor: pointer;
}

.chat-btn-action-box li .chat-btn-action:hover {
  color: #224878;
}
