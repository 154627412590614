////////////////////////////////////////////////////////////////////////////////

// Flash Message

.flash-message{
  float: left;
  bottom: 0;
  width: 100%;
  background-color: $color-secondary;
  padding: 15px 30px;
  position: relative;
  z-index: 99999;
  transition: all 0.5s;

  &.fixed{
    position: fixed;

  }

  &.top{
    top: 0;
    bottom: inherit;
  }

  &.hidden-flash{
    bottom: -87px;
  }


  h4{
    color: $color-white;
    font-weight: 700;
    margin: 0;
    display: inline-block;
    span{
      font-weight: 300;
    }
    i{
      padding-left: 20px;
    }
  }

  a.close{
    position: absolute;
    right: 0;
    line-height: 10px;
    float: right;
    color: $color-gray-secondary;
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    padding-right: 15px;
    top: 50%;
    margin-top: -10px;
  }

  // SUCCESS

  &.success{
    background-color: $color-secondary;
    h4{
      i{
        color: #00FF80;
      }
    }
  }

  // ERROR

  &.error{
    background-color: $color-primary;
    h4{
      i{
        display: none;
      }
    }
  }

}


// Alert Backend
.alert{
  position: fixed;
  bottom: 0;
  margin: 0;
  z-index: 9999999;
  width: 100%;
  left: 0;
  border-radius: 0;
  text-align: center;
  font-size: 1.6em;
  border: 0;
  color: #fff;
  line-height: normal;
  padding-bottom: 12px;
  i{
    margin-left: 10px;
  }

  &.alert-success{
    background-color: #2ecc71;

  }
  &.alert-danger{
    background-color: #e74c3c;
  }

  button.close{
    line-height: normal;
    position: absolute;
    right: 15px;
    top: 8px;
  }
}
