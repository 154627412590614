.chat-box-main-container{
  position: fixed;
  position: fixed;
  bottom: 0;
  right: 5px;
  z-index: 9999;
  transition: all 0.45s;
  pointer-events: none;
}

.chat-options-btn{
  position: absolute;
  top: 10px;
  right: 13px;
  color: $color-white;
  font-size: 1.4em;
  z-index: 4;
  opacity: 1;

  .ios-checkbox{
    i{
      margin: 0;
      background-color: rgba($color-text-primary, 0.5);

      .circle{
        border-color: transparent;
      }
    }
  }

  &.chatbox-close{
    opacity: 0.5;
    width: 35px;
    line-height: 35px;
    text-align: center;
    top: 0;
    right: 0;
    cursor: pointer;

    &:hover{
      opacity: 1;
      color: $color-white;
    }

  }
}

.chat-box{
  display: inline-block;
  vertical-align: bottom;
  margin-right: 10px;
  position: relative;
  width: 300px;
  pointer-events: auto;



  &.chat-active{
    .chat-box-header{
      background-color: $color-primary;
      &:hover{
        background-color: $color-primary;
      }
    }
  }

  .chat-box-header{
    background-color: $color-text-primary;
    position: relative;
    color: $color-white;
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
    font-size: 1.4em;
    font-weight: 600;
    border-radius: 4px 4px 0px 0px;
    z-index: 3;
    cursor: pointer;

    .chat-icon{
      margin-right: 5px;
    }

    label{
      margin: 0;
    }

    &:hover{
      text-decoration: none;
      background-color: lighten($color-text-primary, 5%);
    }
  }

  .chat-box-content{
    background-color: lighten($color-gray-secondary, 10%);
    width: 100%;
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-left: solid 1px $color-gray-secondary;
    border-right: solid 1px $color-gray-secondary;
    position: static;

    &.chat-box-content-messages{
      padding-bottom: 0px;
      margin-bottom: 35px;
    }
  }


  // Chat Text Field Message
  .chat-text-field{
    position: absolute;
    bottom: 0;
    margin: 0;
    z-index: 2;

    .form-field{
      border: 0px;
      border-top: solid 1px $color-gray-primary;
      background-color: $color-white;
      resize: none;
      max-height: 100px;
      overflow-y: scroll !important;
      border-radius: 0;
      padding: 5px 10px;
      min-height: 35px;
      float: left;
      border-left: solid 1px $color-gray-secondary;
      border-right: solid 1px $color-gray-secondary;
      // En caso de que hayan Acciones en chat como agregar un video o imagen
      padding-right: 60px;
    }
  }

  .chat-text_message_render{
    border: 0px;
    z-index: 24;
    border-top: solid 1px $color-gray-primary;
    background-color: $color-white;
    display: inline-block;
    font-size: 1.4em;
    resize: none;
    max-height: 100px;
    overflow-y: scroll !important;
    border-radius: 0;
    padding: 5px 10px;
    min-height: 35px;
    float: left;
    border-left: solid 1px $color-gray-secondary;
    border-right: solid 1px $color-gray-secondary;
    width: 100%;
    word-wrap: break-word;
    position: absolute;
    bottom: 0;
    margin: 0;
    font-weight: 400;
    cursor: text;
    display: none;
  }

  // Chat Messages
  .chat-messages{
    list-style: none;
    padding: 0;
    margin: 0;
    position: static;
    z-index: 1;
    padding-bottom: 10px;

    .chat-single-message{
      padding: 35px;
      padding-top: 5px;
      padding-bottom: 0px;
      display: inline-block;
      width: 100%;
      position: relative;

      .photo-profile{
        position: absolute;
        left: 5px;
        top: 5px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $color-gray-secondary;
        display: inline-block;
      }

      .text{
        background-color: $color-gray-secondary;
        display: inline-block;
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 1.4em;
        border-top-left-radius: 0;
        max-width: 85%;
        word-wrap: break-word;
        word-break: break-all;
      }

      .thumb-box{
        display: inline-block;
        width: 100%;
        padding: 0;
        overflow: hidden;
        background-color: $color-secondary;
        position: relative;

        .thumb{
          background-color: $color-secondary;
          display: inline-block;
          border-radius: 20px;
          border-top-left-radius: 0;
          width: 100%;
          height: 150px;
          float: left;
          -webkit-transition: all 0.25s;
          -o-transition: all 0.25s;
          transition: all 0.25s;

          &:hover{
            opacity: 0.5;
          }
        }

        &.thumb-video-box{

          &:after{
            content: "\f04b";
            position: absolute;
            text-align: center;
            top: 50%;
            left:50%;
            -webkit-transform: translateY(-50%) translateX(-50%);
            -ms-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
            color: $color-white;
            font-family: FontAwesome;
            font-size: 1.2em;
            pointer-events: none;
            width: 50px;
            line-height: 50px;
            border-radius: 50%;
            background-color: rgba($color-secondary, 0.5);
            display: inline-block;
          }

        }

      }

      &.user-message{
        text-align: right;

        .photo-profile{
          left: auto;
          right: 3px;
        }

        .text{
          background-color: $color-text-primary;
          color: $color-white;
          border-radius: 20px;
          border-top-right-radius: 0;
        }

        // User Thumbs
        .thumb-box{
          .thumb{
            border-radius: 20px;
            border-top-right-radius: 0;
          }
        }
      }
    }

    .chat-message-date{
      text-align: center;
      color: $color-text-secondary;
      border-bottom: solid 1px $color-gray-secondary;
      height: 18px;
      margin-bottom: 10px;
      margin-top: 10px;

      .date{
        padding: 0px 10px;
        background-color: $color-white;
      }
    }
  }

  // ChatBox Group
  &.chat-box-group{
    width: auto;
  }

}


.chat-box-single{
  margin-right: -300px;
  opacity: 0;
  transition: all 0.25s;

  &.chat-box-open{
    margin-right: 5px;
    opacity: 1;
  }
}


// Menu Open
body.menu-open{
  .chat-box-main-container{
    right: -290px;
  }
}
