////////////////////////////////////////////////////////////////////////////////

// Fonts Style
$font-family: 'Lato';
$font-family-secondary: 'Klavika';
$fw-black: 900;
$fw-bold: 700;
$fw-semi-bold: 500;
$fw-regular: 400;
$fw-light: 100;


////////////////////////////////////////////////////////////////////////////////


// Solid Colors
$color-primary: #224878;
$color-primary-2: #51ccff;
$color-secondary: #2B2B2B;
$color-editable: #4a86e8;
$color-green: #77d118;
$color-error: #e74c3c;

// Text Colors
$color-text-primary: #4C433B;
$color-text-secondary: #8C8C8C;

// Grey Colors
$color-gray-primary: #A5A9AD;
$color-gray-secondary: #EDEDED;
$color-gray-tertiary: #f7f7f7;

// Basic Colors
$color-white: #fff;
$color-black: #000;


////////////////////////////////////////////////////////////////////////////////


// Shape Propeties
$border-radius-primary: 2px;
$border-radius-secondary: 4px;


////////////////////////////////////////////////////////////////////////////////


// Margins & Paddings
$padding: 80px;
$center-margin: 0 auto;
