////////////////////////////////////////////////////////////////////////////////

// Buttons - General
.row-buttons-group{
  margin-bottom: $padding;
}
.button{
  text-decoration: none;
  font-size: 2em;
  font-family: $font-family-secondary;
  font-weight: $fw-semi-bold;
  line-height: 22px;
  background-color: $color-primary;
  padding: 20px;
  color: $color-white;
  display: inline-block;
  border: 0;
  transition: all 0.25s;
  text-align: center;
  position: relative;
  margin-bottom: 5px;
  min-width: 230px;
  i{
    position: absolute;
    background-color: transparent;
    left: 0;
    top: 0;
    width: 50px;
    line-height: 62px;
  }

  &:hover{
    color: $color-white;
    text-decoration: none;
    box-shadow: inset 0px -6px 0px rgba($color-black, 0.15);
  }

  &:focus{
    outline: 0;
    color: $color-white;
    text-decoration: none;
  }
}

.button-responsive{
  width: 100%;
  max-width: 280px;
}

////////////////////////////////////////////////////////////////////////////////

// Buttons - Size
.button-large{
  font-size: 2.4em;
  padding: 20px 40px;
}
.button-medium{
  font-size: 1.6em;
  font-weight: $fw-semi-bold;
  padding: 15px 30px;
  i{
    line-height: 52px;
  }
}
.button-small{
  font-size: 1.4em;
  padding: 5px 20px;
  i{
    line-height: 32px;
  }
}

.button-short{
  font-size: 1.4em;
  padding: 5px 15px;
}


////////////////////////////////////////////////////////////////////////////////

// Buttons - Colors
.button-primary{background-color: $color-primary;}
.button-secondary{background-color: $color-secondary;}
.button-tertiary{
  background-color: $color-text-primary;
  color: $color-white;
}
.button-white{
  background-color: $color-white;
  color: $color-primary;
  &:hover{
      color: $color-primary;
  }
  &:focus{
      color: $color-primary;
  }
}

////////////////////////////////////////////////////////////////////////////////

// Buttons Style
.button-outline{
  border: solid 1px $color-primary;
  background-color: transparent;
  color: $color-text-primary;
  font-weight: bold;

  &:hover, &:focus{
    background-color: $color-primary;
    color: $color-white;
  }

  &.active{
    border-color: $color-primary;
    background-color: $color-primary;
    color: $color-white;
  }
}

.button-edit{
  background-color: $color-editable;
  font-weight: 500;
}

.button-icon{

  padding-left: 40px;
  font-size: 1.6em;
  font-weight: 400;
  background-color: transparent;
  color: $color-text-primary;
  box-shadow: 0px 0px 0px 1px $color-primary inset;

  i{
    position: absolute;
    background-color: transparent;
    left: 0;
    top: 0;
    width: 60px;
    line-height: 42px;
    border-radius: 50%;
  }

  &:hover{
    background-color: $color-primary;
    color: $color-white;
  }

  &.button-small{
    i{
      line-height: 32px;
    }
  }
}

.button-circle{
  width: 50px;
  line-height: 50px;
  border: solid 1px $color-gray-primary;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 1.6em;
  margin: 0 10px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;

  &:hover{
    border-color: $color-primary;
    color: $color-white;
    background-color: $color-primary;
  }
}

.button-input{
  border-radius: 0;
}
