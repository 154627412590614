//////////////////////////////////////////

// Header
header.main-header{

  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-transition: all 0.45s;
  -o-transition: all 0.45s;
  transition: all 0.45s;


  position: fixed;
  width: 100%;
  z-index: 9999999;
  top: 0px;
  height: 70px;
  left: 0;
  background-color: $color-white;
  border-bottom: solid 1px $color-gray-secondary;

  .container{
    max-width: 1280px;
  }

  .logo{
    display: inline-block;
    padding-top: 15px;
    *{
      display: inline-block;
    }

    img{
      max-width: 90px;
    }
  }

  &.fixed-header{
    position: fixed;
    top: 0;
    background-color: $color-white;
  }


  // Search Header Input
  .header-search-input-box{
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 94.69px;
    display: none;

    .field-icon{
      left: 94px;
      line-height: 70px;
      height: 100%;
      background-color: transparent;
      width: 43px;
      color: $color-primary;
      font-size: 1.4em;
    }

    .form-field{
      height: 70px;
      padding: 0;
      padding-left: 52px;
      font-weight: 600;
      color: $color-text-primary;
      border: 0;
      line-height: 70px;
      background-color: $color-white;
    }
    &.field-focus{
      .field-icon{
        background-color: transparent;
      }
    }

    input:focus + .button{
      -webkit-transform:none;
      -ms-transform:none;
      transform:none;
    }

    .button{
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0;
      height: 100%;
      -webkit-transform:translateX(100%);
      -ms-transform:translateX(100%);
      transform:translateX(100%);
    }
  }

  .submenu-user-actions-box.submenu-user-logged{
    .submenu-user-actions{
      li{
        a{
          padding: 10px 20px;
        }
      }
    }
  }
}

.header-search-overlay{
  z-index: 999999;
  opacity: 0.75;
  display: none;
  cursor: url(/assets/online/img/cursor-arrow-up.png) 35 35, auto;
}

body.header-search-open{
  overflow: hidden;
}

.home-page-class{
  header.main-header{
    // border-bottom: solid 0 $color-gray-primary;

    // ul.menu{
    //   &.menu-horizontal{
    //     li{
    //       a{
    //         color: $color-white;
    //       }
    //     }
    //   }
    // }

    // &.fixed-header{
    //   background-color: $color-white;
    //
    //   ul.menu{
    //     &.menu-horizontal{
    //       li{
    //         a{
    //           color: $color-text-primary;
    //
    //           &.menu-cta{
    //             color: $color-white;
    //           }
    //         }
    //
    //         &.submenu-box{
    //           a{
    //             color: $color-text-primary;
    //           }
    //
    //           .submenu{
    //             li{
    //               a{
    //                 color: $color-white;
    //               }
    //             }
    //           }
    //         }
    //
    //       }
    //     }
    //   }
    //
    // }
  }
}


body.admin-front{
  .top-header-box{
    top: 37px;
  }

  header.main-header{
    top: 73px;
  }

  header.main-header.fixed-header{
    top: 37px;
  }
}

.home-page-class{
  header.main-header{
    // background-color: rgba($color-white, 0.2);

    ul.menu.menu-horizontal li a{
      &:hover{
        color: $color-primary;
      }
    }

  }
}

// Header Perfil y Programa
.online-view{
  header.main-header{
    position: absolute;
    top: 36px;
    -webkit-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;

    &.fixed-header{
      position: fixed;
      top: 0;
    }
  }
}
