////////////////////////////////////////////////////////////////////////////////

// Vertical Align
.va-middle{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform:  translateY(-50%);
  -webkit-transform: translateY(-50%);
}

// Floats
.left{float: left;}
.right{float: right;}
.no-float{float: none;}


////////////////////////////////////////////////////////////////////////////////

// Paddings

.null-padding{padding: 0 !important;}
.null-padding-left{padding-left: 0 !important}
.null-padding-right{padding-right: 0 !important}
.null-padding-top{padding-top: 0 !important}
.null-padding-bottom{padding-bottom: 0 !important}
.null-padding-side{padding-left: 0 !important; padding-right: 0 !important}


////////////////////////////////////////////////////////////////////////////////

// Backgrounds

.bg-s-cover{
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.bg-a-fixed{background-attachment: fixed;}

// Background Colors
.bg-primary{ background-color: $color-primary;}
.bg-secondary{ background-color: $color-secondary;}
.bg-text-primary{ background-color: $color-text-primary;}
.bg-gray-primary{ background-color: $color-gray-primary;}
.bg-gray-secondary{ background-color: $color-gray-secondary;}
.bg-gray-tertiary{ background-color: $color-gray-tertiary;}
.bg-white{ background-color: $color-white;}
.bg-black{ background-color: $color-black;}

////////////////////////////////////////////////////////////////////////////////

// Text Colors
.color-text-primary{ color: $color-primary;}
.color-text-secondary{ color: $color-secondary;}
.color-text-gray-primary{ color: $color-gray-primary;}
.color-text-gray-secondary{ color: $color-gray-secondary;}
.color-text-white{ color: $color-white;}
.color-text-black{ color: $color-black;}
.color-text-1{ color: $color-text-primary;}
.color-text-2{ color: $color-text-secondary;}

////////////////////////////////////////////////////////////////////////////////

// Text Weight

.font-regular{font-weight: 400;}
.font-semibold{font-weight: 500;}
.font-bold{font-weight: 600;}


////////////////////////////////////////////////////////////////////////////////

// Girar Objetos
.girar-90{
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
}
.girar-180{
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}
.girar-270{
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
}


////////////////////////////////////////////////////////////////////////////////

// Mix Blend Mode
.mbm-multiply{ mix-blend-mode:multiply;}



////////////////////////////////////////////////////////////////////////////////

// Overlay
.overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}


////////////////////////////////////////////////////////////////////////////////


// Sections

.section{
  padding-top: $padding;
  padding-bottom: $padding;
}

.section-small{
  padding-top: $padding/2;
  padding-bottom: $padding/2;
}

.section-short{
  padding-top: $padding/4;
  padding-bottom: $padding/4;
}


////////////////////////////////////////////////////////////////////////////////

// Masonry Item
.masonry-item{
  display: inline-block;
  float: none;
  margin-left: -1px;
  margin-right: -2px;
  vertical-align: top;
}
.masonry-va-top{
  vertical-align: top;
}

.masonry-va-middle{
  vertical-align: middle !important;
}

.photo{
  background-position: center;
}

////////////////////////////////////////////////////////////////////////////////

.vertical-line{
  width: 1px;
  min-height: 100px;
  background-color: $color-gray-primary;
  display: inline-block;
}

.colum-grid{
  padding-left: 5px;
  padding-right: 5px;
}
