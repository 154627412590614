//////////////////////////////////////////

// Footer
footer.main-footer{
  background-color: $color-primary;
  color: $color-text-secondary;
  font-weight: 500;
  // position: fixed;
  bottom: 0;

  a{
    &:hover{
      color: $color-primary;
      text-decoration: none;
    }
  }

  .container{
    max-width: 1280px;
    width: 100%;
    padding: 0;
  }

  p{
    padding-top: 4px;
  }

  .footer-content{

    .jnq-logo{
        opacity: 0.8;
        &:hover{
            opacity: 1;
        }
    }

    &.part1{
        border-right: solid 1px darken($color-primary, 8%);
        .part-box{
          // max-width: 400px;
          float: right;
        }
    }

      .logo{
          img{
              margin-bottom: 7px;
              margin-right: 10px;
              max-width: 70px;
              display: inline-block;
          }
      }
      .text-info{
          display: inline;
          color: rgba($color-white, 0.4);
          line-height: 35px;
          padding-right: 5px;
          font-size: 1.5em;
      }

      .social-links{
        float: left;
        list-style: none;
        padding: 0;
        margin: 0;
        // margin-top: 10px;

        li{
          display: inline-block;
          font-size: 2em;
          margin: 0px 10px;
          line-height: 35px;

          a{
            color: rgba($color-white, 0.6);

            &:hover{
              color: $color-white;
            }
          }

        }
      }
  }



  .footer-rights{
    a{
      color: $color-text-primary;
      &:hover{
        color: $color-primary;
      }
    }
  }
}

.nuestras-obras-page-class{
  footer.main-footer .footer-content .social-links{
    margin-top: 10px;
  }
}
