// Text Styles
h1.banner-title{
  font-size: 5.6em;
  font-weight: $fw-regular;
  margin: 0 0 30px 0px;
}
h1.hero-heading{
  font-size: 3.6em;
}

h1, h2, h3, h4, h5, h6{
  font-weight: $fw-regular;
  color: $color-text-primary;
  margin: 0;
}

h1{
  font-size: 3.2em;
  font-family: $font-family-secondary;
  font-weight: $fw-regular;
}
h2{
  font-size: 2.6em;
}
h3{
  font-size: 2em;
}
h4{
  font-size: 1.8em;
}
h5{
  font-size: 1.6em;
}
h6{
  font-size: 1.4em;
}
p{
  font-size: 1.4em;
  line-height: 1.6em;
}
p.paragraph-smaller{
  font-size: 1.4em;
  line-height: 1.6em;
}

hr{
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
