@font-face {
    font-family: 'Klavika';
    src: url(/assets/online/base/scss/fonts/klavika-regular.eot),
         url(/assets/online/base/scss/fonts/klavika-regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Klavika';
    src: url(/assets/online/base/scss/fonts/klavika-medium.eot),
         url(/assets/online/base/scss/fonts/klavika-medium.ttf);
    font-weight: 500;
}
