@media all and (min-width: 769px){

// ScrollBar Body
body::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
}

body::-webkit-scrollbar-track {
    background-color: $color-secondary;
}

body::-webkit-scrollbar-thumb {
  background-color: $color-primary;
}

// ScrollBar General
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $color-primary;
}

}
